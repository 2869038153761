import { gql } from 'apollo-angular';

export const AddOrganizationRequest = gql`
mutation addOrganization($name:String!,$logo: String, $emailAddress: String!,$street: String!,$address: String,
  $city: String!,$state: String!, $areaCode: String!,$zipCode: String!,$domain: String, $phone: String!,$timezoneId:Int,$policyUrl: String!,$sequences: [SequenceInput]!) {
  addOrganization(name:$name,logo: $logo,state: $state,zipCode: $zipCode,emailAddress:$emailAddress, address: $address
    city:$city, areaCode: $areaCode,domain: $domain, phone:$phone,street: $street, timezoneId:$timezoneId,policyUrl:$policyUrl,sequences: $sequences){
    message
  }
}`;

export const EditOrganizationRequest = gql`
mutation updateOrganization(
  $id: Int!,
  $name:String!,
  $logo: String,
  $emailAddress: String!,
  $street: String!,
  $address: String!,
  $city: String!,
  $state: String!,
  $zipCode: String!,
  $domain: String!,
  $phone: String!,
  $timezoneId:Int,
  $policyUrl: String!,
  $areaCode: String!,
  $sequences: [SequenceInput]!,
  $userRights: [UserRightInput]
) {
    updateOrganization(
      id: $id,
      name:$name,
      logo: $logo,
      state: $state,
      zipCode: $zipCode,
      emailAddress:$emailAddress,
      address: $address
      city:$city,
      domain: $domain,
      phone:$phone,
      areaCode:$areaCode,
      street: $street,
      timezoneId: $timezoneId,
      policyUrl: $policyUrl
      sequences: $sequences,
      userRights: $userRights
    ){
    message
  }
}`;

export const DeleteOrganizationRequest = gql`
mutation deleteOrganization(
  $id: Int!
) {
  deleteOrganization(
      id: $id
    ){
    message
  }
}`;

export const GetOrganizations = gql`
query getOrganizations
  (
    $search: OrganizationFilter
    $pagination: Pagination
    $sort: String
    $sortOrder: SortOrder
  )
  {
    getOrganizations(
      search: $search
      pagination: $pagination
      sort: $sort
      sortOrder: $sortOrder
    ){
      organizations {
        id
        name
        logo
        emailAddress
        domain
        street
        address
        city
        state
        zipCode
        phone
        formattedPhoneNumber
        areaCode
        Timezone{
          id
          name
          offset
          code
        }
        policyUrl
        sequences {
          id
          name
          status
          organizationStatus
          description
          sentEveryDays
          startingDateTime
          endingDateTime
        }
        userRights {
          rightName,
          rightGroup,
          rightCode,
          administrator,
          juniorManager,
          seniorManager
        }
        OrganizationConfig {
          id
          settings {
              EMAIL_SEND_FROM
          }
        }
      },
      pagination {
        totalRecords
        totalPages
        page
      }
    }
  }
`;

export const GetOrganization = gql`
query {
  getOrganization{
  id
    name
    logo
    emailAddress
    domain
    street
    address
    city
    state
    zipCode
    phone
    formattedPhoneNumber
    areaCode
    Timezone{
      id
      name
      offset
      code
    }
    policyUrl
    sequences {
      id
      name
      status
      activities
      description
      sentEveryDays
      startingDateTime
      endingDateTime
      organizationStatus
    }
    userRights{
      rightGroup
      rightName
      rightCode
      administrator
      juniorManager
      seniorManager
    }
    OrganizationConfig{
      id
      settings{
        EMAIL_SEND_FROM
        EMAIL_SEND_NAME
      }
    }
  }
}
`;
export const createAdminUserRequest = gql`
mutation inviteBackendUser($firstName:String!,$lastName:String!,$email:String!,
  $areaCode:String!,$phone:String!,$organizationId:Int,$roleId: Int!, ) {
  inviteBackendUser(firstName: $firstName, lastName: $lastName, email: $email,
    areaCode:$areaCode, phone:$phone,organizationId:$organizationId,roleId:$roleId){
    message
  }
}`;

export const GetAdmins = gql`
query getAdmins{
  getAdmins{
    id
    name
  }
}`;

export const GETSEQ = gql`
query getSequences{
  getSequences(pagination: {items: 100, page: 1}, sort: "createdAt"){
    id
    name
    description
    status
    organizationStatus
  }
}`;

export const ADMININVITE = gql`
mutation acceptDeclineAdminManagerInvitation($invitationCode: String!, $acceptDecline: InvitationStatus!){
  acceptDeclineAdminManagerInvitation(invitationCode: $invitationCode , acceptDecline: $acceptDecline) {
    message
  }
}
`;
export const UPDATEORGANIZATIONCONFIG = gql`
mutation updateOrganizationConfig($id: Int!,$settings: OrganizationSettingsInput){
  updateOrganizationConfig(id:$id, settings:$settings){
    message
  }
}
`;
export const ADDORGANIZATIONCONFIG = gql`
mutation addOrganizationConfig($settings: OrganizationSettingsInput!){
  addOrganizationConfig(settings:$settings){
    message
  }
}
`;

export const GETORGANIZATIONONBOARDING = gql`
query{
  organizationOnboardingStatus{
    turnOnSequence
    configureIntegration
    addOtherAdministrativeUsers
    uploadListsOfEmployees
    setUpYourOrganization
  }
}
`;

export const ORGANIZATIONEXPORTCSV = gql`
mutation{
  exportCsv(model:USER){
    message
  }
}
`;
export const GET_LIST_HISTORY =  gql`
query getListHistory($listId: Int!){
  getListHistory(listId: $listId){
    createdAt
    systemActivity
    count
    sequence
  }

}`;
export const GET_TIME_ZONES = gql`
query{
  getTimezones{
    id,
    name,
    offset,
    code
  }
}
`;
export const CREATE_DYNAMIC_TASK = gql`
  mutation CreateDynamicTask($title: String!, $description: String!, $filters: FilterInput!) {
    createDynamicTask(title: $title, description: $description, filters: $filters) {
      message
    }
  }
`;
export const GET_DYNAMIC_TASK = gql`query GetDynamicTaskList($sort: String, $orderBy: SortOrder, $search: SearchDynamicTask, $isActive: Boolean, $pagination: Pagination) {
  getDynamicTaskList(sort: $sort, orderBy: $orderBy, search: $search, isActive: $isActive, pagination: $pagination) {
    dynamicTasks {
      id
      title
      description
      isActive
      DynamicTaskFilters {
        filterType
        operator
        value
        valueObject
      }
      details
    }
    pagination {
      totalRecords
      totalPages
      page
    }
  }
}`;

export const UPDATE_DYNAMIC_TASK = gql`
  mutation UpdateDynamicTaskStatus($updateDynamicTaskStatusId: Int!, $isActive: Boolean!) {
    updateDynamicTaskStatus(id: $updateDynamicTaskStatusId, isActive: $isActive) {
      message
    }
  }
`;
export const DELETE_DYNAMIC_TASK = gql`
  mutation DeleteDynamicTask($deleteDynamicTaskId: Int!) {
    deleteDynamicTask(id: $deleteDynamicTaskId) {
      message
    }
  }
`;
export const GET_GROUP_SIZE = gql`
  query GetUserGroupSize($dynamicTaskId: Int!) {
    getUserGroupSize(dynamicTaskId: $dynamicTaskId) {
      value
      title
    }
  }
`;
