
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { getDashboardStats, getDashboardChartData } from './dashboard-stats.request';
import { DashboardStatsResponse } from './dashboard-stats.model';
import { Observable } from 'rxjs';


@Injectable()
export class DashboardStatService {
    constructor(
      private apollo: Apollo,
      ) {}

    getDashboardStats(dashboardRefinements?): Observable<DashboardStatsResponse> {
      return this.apollo.query({
        query: getDashboardStats,
        variables: dashboardRefinements,
        fetchPolicy: 'network-only'
      });
    }

    getDashboardChartData(dashboardRefinements): Observable<DashboardStatsResponse> {
      return this.apollo.query({
        query: getDashboardChartData,
        variables: dashboardRefinements,
        fetchPolicy: 'network-only'
      });
    }
}
