import { DashboardStatsRequest } from "./dashboard-stats.model"

export class DashboardStatsAction {
    static readonly type = '[DASHBOARD] DashboardStats'
    constructor(public reqObj : DashboardStatsRequest) {}
}


export class DashboardChartAction {
    static readonly type = '[DASHBOARD] Dashboard Chart'
    constructor(public reqObj : DashboardStatsRequest) {}
}

export class SetChartExtraAction {
    static readonly type = '[DASHBOARD] Chart Extra Data'
    constructor(public index : number, public series : string) {}
}