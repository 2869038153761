import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toSentence' })
export class ConvertString implements PipeTransform {
  transform(text: string, type?: string) : string {
    let sen: string
    if(!text) {
    return '';
    }
    if(text && type === 'enum') {
      text = text.toUpperCase()
      text = text.replaceAll(' ','_')
      text = text.replaceAll('-','_')
      return text
    }
    sen =  text.toLowerCase()
    sen = sen.replaceAll('_', ' ');

    const arr = sen.split(" ");
    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }

    let str2 = arr.join(" ");
    if(type && type === 'docs'){
      return str2.split(' ')[0].toUpperCase() + ' ' + this.removeFirstWord(str2);

    }

    str2 = str2.replace(/covid/gi, 'COVID');
    // tslint:disable-next-line:indent
	  str2 = str2.replace(/covid 19/gi, 'COVID-19');
    return str2;
  }
  removeFirstWord(str) {
    const indexOfSpace = str.indexOf(' ');

    if (indexOfSpace === -1) {
      return '';
    }

    return str.substring(indexOfSpace + 1);
  }
}
