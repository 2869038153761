import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import {  UserStateModal } from './user.model';
import { UserService } from './user.service';
import { appsignal } from 'app/app.module';
import  * as userActions from './user.action';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Types from 'app/shared/util/types';
import { GetTimeZones } from '../OrganizationStore/organization.action';
import { cloneDeep } from 'lodash';
import {ContentData} from '../GeneralStore/general-store.actions';
@State<UserStateModal>({
    name: 'User',
    defaults: {
        user: null,
        token: null,
        loading: null,
        role: undefined,
        organizations: undefined,
        loginError: '',
        contextData: undefined,
        userProfile: null,
        organizationId: null,
        refreshToken: null,
        userRoles: null,
        backendUsers: [],
        backendUsersLoading: false,
        backendUsersPagination: {
            page: 1,
            totalRecords: 0,
            totalPages: 1
        },
        primaryAdmins: [],
        updateAdmin: null,
        invitationCodeInfo: null,
        isLoggedIn: false,
        userRights: undefined,
        invitationObj: null
    }
})

@Injectable()
export class UserState {

    constructor( private apiService: UserService,
        public authService: JwtAuthService,
        private snackBar: MatSnackBar ) {}

    openSnackBar(mess) {
        this.snackBar.open(mess, 'Close', { duration: 5000});
    }

    @Action(userActions.AdminLogin)
    updateCurrUserSettings({ patchState, dispatch }: StateContext<UserStateModal> , {adminObj}) {
        patchState({loading: true});

        return this.apiService.adminLogin(adminObj)
        .pipe(
            tap(async (res) => {
                patchState({loginError: null ,
                    loading: false,
                    user: res.data.adminAppLogin.user,
                    token: res.data.adminAppLogin.token,
                    role: res.data.adminAppLogin.roles[0].id,
                    organizationId: res.data.adminAppLogin.organizationId,
                    refreshToken: res.data.adminAppLogin.refreshToken,
                    invitationObj: res.data.adminAppLogin.invitation
                });
               dispatch(new userActions.SetCurrentUserRoles());
                dispatch(new userActions.GetUserDataAction());
            })
        ).pipe(catchError(error => {
            patchState({
                loginError: error.graphQLErrors[0].message, loading: false
            });
            appsignal.sendError(error , (span) => {
                span.setAction('user Error ');
                span.setNamespace('user login');
                span.setTags({ tag1: 'login' });
            });
            patchState({loading: false});
            return [];
        }));
    }
    @Action(userActions.GetOrganaizationsAction)
    getOrganizations({patchState}: StateContext<UserStateModal>) {
        patchState({ loading: true});
        return this.apiService.getOrganizations()
        .pipe(
            tap((res) => {
                patchState({  loading: false, organizations: res.data.getOrganizations });

            })
        ).pipe(catchError(error => {

            patchState({loading: false});
            return [];
        }));
    }

    @Action(userActions.GetContextDataAction)
    getContextData({patchState , dispatch}: StateContext<UserStateModal>, {userId}) {
        return this.apiService.getContextData(userId)
        .pipe(
            tap((res) => {
                dispatch(new ContentData());
                patchState({contextData: res.data.getContentData });
            })
        ).pipe(catchError(error => {
            this.openSnackBar(error);
            return [];
        }));
    }
    @Action(userActions.GetUserDataAction)
    getUserData({patchState}: StateContext<UserStateModal>) {
        return this.apiService.getUserData()
        .pipe(
            tap((res) => {
                patchState({userProfile: res.data.getUserProfile });
            })
        ).pipe(catchError(error => {
            return [];
        }));
    }
    @Action(userActions.SetCurrentUserRoles)
    setUserRoles({patchState}: StateContext<UserStateModal>) {
      return  this.apiService.getUserRoleAndRight()
       .pipe(
           tap((res) => {
            patchState({userRoles: res.data.getUserRoleAndRights.role, userRights: res.data.getUserRoleAndRights.userRights });
           })
       ).pipe(catchError(error => {
           this.openSnackBar(error);
        return [];
    }));

    }

    @Action(userActions.ChangePassword)
    changePassword({ patchState}: StateContext<UserStateModal> , {obj}) {
        patchState({ loading: true});
        return this.apiService.changePassword(obj)
        .pipe(catchError(error => {
            return [];
        }))
        .pipe(
            tap((res) => {
                patchState({ loading: false});
            })
        );
    }

    @Action(userActions.GetBackendUsers)
    getBackendUsers({patchState}: StateContext<UserStateModal>, {reqObj}) {
        patchState({ backendUsersLoading: true, backendUsers: [],
            backendUsersPagination: {
            page: 1,
            totalRecords: 0,
            totalPages: 1
        }});
        return this.apiService.getBackendUsers(reqObj)
        .pipe(
            tap((res) => {
                patchState({  backendUsersLoading: false, backendUsers: res.data.getBackendUsers.users, backendUsersPagination: res.data.getBackendUsers.pagination });

            })
        ).pipe(catchError(error => {
            appsignal.sendError(error , (span) => {
                span.setAction('user Error ');
                span.setNamespace('user backend users');
                span.setTags({ tag1: 'get backend users' });
            });
            patchState({ backendUsersLoading: false, backendUsers: [], backendUsersPagination: {
                page: 1,
                totalRecords: 0,
                totalPages: 1
            }});
            return [];
        }));
    }

    @Action(userActions.DeleteBackendUser)
    deleteBackendUser({patchState, dispatch}: StateContext<UserStateModal>, {userId, roles, invitationStatus}) {
        return this.apiService.deleteBackendUser(userId)
        .pipe(
            tap((res) => {
                this.openSnackBar(res.data.deleteAdminUser.message);
                dispatch(new userActions.DeleteBackendUserFromState(userId));
            })
        ).pipe(catchError(error => {
            appsignal.sendError(error , (span) => {
                span.setAction('user Error ');
                span.setNamespace('user backend user');
                span.setTags({ tag1: 'delete backend user' });
            });
            this.openSnackBar(error);
            return [];
        }));
    }

    @Action(userActions.UpdateAdminUser)
    updateBackendUser({patchState, getState}: StateContext<UserStateModal>, {reqObj}) {
        patchState({ updateAdmin: true });
        return this.apiService.updateBackendUser(reqObj)
        .pipe(
            tap((res) => {
                const state = getState();
                const users = state.backendUsers.map(_res => {
                    const data = cloneDeep(_res);
                    if ( data.id === reqObj.updateAdminUserId ) {
                        data.firstName = reqObj.firstName;
                        data.lastName = reqObj.lastName;
                        data.email = reqObj.email;
                        data.areaCode = reqObj.areaCode;
                        data.phone = reqObj.phone;
                        return data;
                    }
                    return data;
                });
                patchState({ updateAdmin: null, backendUsers: users });
                this.openSnackBar(res.data.updateAdminUser.message);
            })
        ).pipe(catchError(error => {
            patchState({ updateAdmin: null });
            appsignal.sendError(error , (span) => {
                span.setAction('user Error ');
                span.setNamespace('user backend user');
                span.setTags({ tag1: 'update backend user' });
            });
            this.openSnackBar(error);
            this.openSnackBar(error);
            return [];
        }));
    }

    @Action(userActions.DeleteBackendUserFromState)
    deleteBackendUserFromSate({patchState, getState}: StateContext<UserStateModal>, {userId}) {
        const state = getState();
        const users = state.backendUsers.filter(user => (user.id !== userId));
        const paginationState = state.backendUsersPagination;
        const pagination: Types.PaginationResponse = {
            page: paginationState.page,
            totalPages: paginationState.totalPages,
            totalRecords: paginationState.totalRecords - 1
        };
        patchState({backendUsers: users, backendUsersPagination: pagination});
    }

    @Action(userActions.BackendUserResetPassword)
    resetBackendUserPassword({dispatch}: StateContext<UserStateModal>, {userId}) {
        return this.apiService.resetBackendUserPassword(userId)
        .pipe(
            tap((res) => {
                this.openSnackBar(res.data.resetPassword.message);
            })
        ).pipe(catchError(error => {
            appsignal.sendError(error , (span) => {
                span.setAction('user Error ');
                span.setNamespace('user backend user');
                span.setTags({ tag1: 'backend user reset password' });
            });
            this.openSnackBar(error);
            return [];
        }));
    }
    @Action(userActions.GetPrimaryAdmins)
    getPrimaryAdmins({patchState}: StateContext<UserStateModal>) {
        patchState({primaryAdmins: []});
        return this.apiService.getPrimaryAdmins()
        .pipe(
            tap((res) => {
                patchState({primaryAdmins: res.data.getOrganizationAdmins});
            })
        ).pipe(catchError(error => {
            appsignal.sendError(error , (span) => {
                span.setAction('user Error ');
                span.setNamespace('primary admins');
                span.setTags({ tag1: 'get primary admins' });
            });
            this.openSnackBar(error);
            return [];
        }));
    }
    @Action(userActions.UpdatePrimaryAdmin)
    updatePrimaryAdmin({dispatch}: StateContext<UserStateModal>, {userId}) {
        return this.apiService.updatePrimaryAdmin(userId)
        .pipe(
            tap((res) => {
                this.openSnackBar(res.data.updateOrganizationPrimaryAdmin.message);
                dispatch(new userActions.GetPrimaryAdmins());
            })
        ).pipe(catchError(error => {
            appsignal.sendError(error , (span) => {
                span.setAction('user Error ');
                span.setNamespace('primary admins');
                span.setTags({ tag1: 'update primary admin' });
            });
            this.openSnackBar(error);
            return [];
        }));
    }
    @Action(userActions.UpdateUserProfile)
    updateUserProfile({patchState , dispatch}: StateContext<UserStateModal>, {reqObj}) {
        patchState({updateAdmin: true, loading: true});
        return this.apiService.updateUserProfile(reqObj)
        .pipe(
            tap((res) => {
                this.openSnackBar(res.data.updateUserProfile.message);
                patchState({updateAdmin: false, loading: false});
                dispatch(new userActions.GetUserDataAction());
            })
        ).pipe(catchError(error => {
            appsignal.sendError(error , (span) => {
                span.setAction('user Error ');
                span.setNamespace('admin user');
                span.setTags({ tag1: 'update admin user' });
            });
            this.openSnackBar(error);
            patchState({updateAdmin: false});
            return [];
        }));
    }

    @Action(userActions.GetInvitationTokenInfo)
    getInvitationTokenInfo({ patchState}: StateContext<UserStateModal>, {reqObj}) {
        patchState({ loading: true});
        return this.apiService.getInvitationTokenInfo(reqObj)
        .pipe(
            tap((res) => {
                patchState({  loading: false, invitationCodeInfo: res.data.getInvitationTokenInfo.email});
            })
        ).pipe(catchError(error => {
            this.openSnackBar(error);
            return [];
        }));
    }
    @Action(userActions.AfterLoginAction)
    setLoginStatus({dispatch, getState}: StateContext<UserStateModal>) {

       dispatch(new userActions.GetUserDataAction());
       dispatch(new  userActions.SetCurrentUserRoles());
       dispatch(new  userActions.GetContextDataAction());
       dispatch(new  GetTimeZones());
    }
}
