import { State, Action, StateContext, Select } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { catchError, take, tap } from 'rxjs/operators';

import { contactTracingAction, PersonActivityListRequestObject, PersonActivityResponseObject, PersonActivityStateModel, PersonVaccCardRespObj, PersonVaccCardsReqObj } from './person-activity.model';
import { PersonActivityService } from './person-activity.service';
import { FileEntity, SortOrder } from 'app/shared/util/types';
import * as PersonAction from './person-activity.action';
import * as Types  from 'app/shared/util/types';
import { GetUsersResquestObject, PeopleModel, SingleUserObject } from '../people-store/people.model';
import { GetSingleUserAction, GetUsersAction } from '../people-store/people.action';
import { GetLists } from '../list-store/list.action';
import { ListRequest } from '../list-store/list.model';
import { UtilService } from 'app/shared/services/utils.service';
import * as _ from 'lodash';
import { GetSequencesCriteria } from '../GeneralStore/general-store.actions';
import { cloneDeep } from 'lodash';
import { PeopleSelectors } from '../people-store/people.selectors';
import { Observable } from 'rxjs';

const model  = {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    areaCode: null,
};

@State<PersonActivityStateModel>({
    name: 'PersonActivity',
    defaults: {
        userId: null,
        loading: null,
        activities: [],
        err: null,
        message: null,
        userVaccDetail: null,
        userVaccCards: [],
        cardLoading: null,
        activitiesLoading: null,
        activityLogs: null,
        contactTracing: [],
        recentContact: null,
        MedicalId: null
    }
})

@Injectable()
export class PersonActivityState {
    @Select(PeopleSelectors.getPeoplePagination) people$: Observable<PeopleModel>;

    constructor(private apollo: Apollo, private apiService: PersonActivityService, public utils: UtilService , ) {}

    private handleError(error: string, patchState: any = null) {
        this.utils.showSnackBar(error);
        if (patchState) {
            patchState({activitiesLoading: false, err: error , loading: false});
        }
        return [];
    }

    @Action(PersonAction.SetuserId)
    setUserId({patchState, dispatch}: StateContext<PersonActivityStateModel>, { userId }) {
        patchState({ userId });
    }
    @Action(PersonAction.GetGeneralData)
    getGeneralData({patchState, getState , dispatch}: StateContext<PersonActivityStateModel>, { loadAllUsers }) {
        dispatch(new PersonAction.ClearPersonActivitiesState());
        if (getState().userId) {
            const personActivityObject: PersonActivityListRequestObject = {
                userId: getState().userId,
                sort: 'activityDate',
                orderBy: Types.SortOrder.DESC,
                pagination: {
                    page: 1,
                    items: 10
                }
            };
            const pvc: PersonVaccCardsReqObj = {
                userId: getState().userId,
                fileEntities: [ FileEntity.TEST_RESULT, FileEntity.VACCINATION_CARD ]
              };
            const usersRequestObj: GetUsersResquestObject = {
                refinements : {
                    userId: getState().userId
                }
            };

            if(loadAllUsers){
                this.people$.pipe(take(1)).subscribe(res=>{
                    const usersReq = {
                        search: '',
                        pagination: res.peoplePagination,
                        refinements: res.peopleRefinements,
                        sort: 'createdAt',
                        sortOrder: 'DESC'
                    };
                    dispatch(new GetUsersAction(usersReq));
                })
            }

            dispatch( new GetSingleUserAction(usersRequestObj));
            dispatch( new  PersonAction.GetPersonActivity(personActivityObject));
            dispatch( new PersonAction.GetPersonVaccDetail());
            dispatch( new PersonAction.GetUserRecentContacts());
            dispatch( new  PersonAction.GetUserVaccCards(pvc));
            dispatch(new  GetSequencesCriteria(getState().userId));
       }

    }
    @Action(PersonAction.GetPersonActivity)
    getPersonActivity({patchState , dispatch}: StateContext<PersonActivityStateModel>, {getPersonActivty}) {
        patchState({
            activitiesLoading: true
        });
        return this.apiService.getPersonActivity(getPersonActivty)
        .pipe(
            tap((res) => {
                patchState({activitiesLoading: false, activities: res.data.getMedicalActivities});
            })
        ).pipe(catchError(error => this.handleError(error, patchState)));
    }

    @Action(PersonAction.AddPersonActivity)
    addPersonActivity({patchState , dispatch}: StateContext<PersonActivityStateModel>, {AddPersonActivityObj}) {
        patchState({
            loading: true
        });
        return this.apiService.addPersonActivity(AddPersonActivityObj)
                    .pipe(
                        tap((res) => {
                            patchState({loading: false, cardLoading: true , message: res.data.addMedicalActivity.message});
                            if (res.data.addMedicalActivity.message) {
                               dispatch(new PersonAction.GetGeneralData());
                               const listRequestObj: ListRequest = {
                                pagination: {
                                  page: 1,
                                  items: 100
                                },
                                sort: 'createdAt',
                                sortOrder: Types.SortOrder.DESC
                              };
                               dispatch(new GetLists(listRequestObj));
                               this.people$.pipe(take(1)).subscribe(res=>{
                                    const usersReq = {
                                        search: '',
                                        pagination: res.peoplePagination,
                                        refinements: res.peopleRefinements,
                                        sort: 'createdAt',
                                        sortOrder: 'DESC'
                                    };
                                    dispatch(new GetUsersAction(usersReq));
                                })
                               this.utils.showSnackBar(res.data.addMedicalActivity.message, 5000);
                            }

                        })
                    ).pipe(catchError(error => this.handleError(error, patchState)));
    }
    @Action(PersonAction.GetPersonVaccDetail)
    getPersonVaccDetails({patchState , getState}: StateContext<PersonActivityStateModel>) {

        patchState({
            loading: true,
            userVaccDetail: null
        });
        return this.apiService.getPersonVaccDetail(getState().userId).pipe(
            tap((res => {
                patchState({ loading: false , userVaccDetail: res.data.getUserVaccinationMenuDetails });

            })
         )
        ).pipe(catchError(error => this.handleError(error, patchState)));

    }

    @Action(PersonAction.UpdatePersonActivity)
    updatePersonActivity({patchState , dispatch}: StateContext<PersonActivityStateModel>, {UpdatePersonActivityObj}) {
        patchState({
            loading: true
        });
        return this.apiService.updatePersonActivity(UpdatePersonActivityObj)
                    .pipe(
                        tap((res) => {
                            patchState({loading: false, message: res.data.updateMedicalActivity.message});
                            if (res.data.updateMedicalActivity.message) {
                                dispatch(new PersonAction.GetGeneralData());
                               const listRequestObj: ListRequest = {
                                pagination: {
                                  page: 1,
                                  items: 100
                                },
                                sort: 'createdAt',
                                sortOrder: Types.SortOrder.DESC
                              };
                               dispatch(new GetLists(listRequestObj));
                               this.people$.pipe(take(1)).subscribe(res=>{
                                    const usersReq = {
                                        search: '',
                                        pagination: res.peoplePagination,
                                        refinements: res.peopleRefinements,
                                        sort: 'createdAt',
                                        sortOrder: 'DESC'
                                    };
                                    dispatch(new GetUsersAction(usersReq));
                                })
                               this.utils.showSnackBar(res.data.updateMedicalActivity.message, 5000);
                             }
                        })
                    ).pipe(catchError((error) => {
                    patchState({ loading: false , err: error});
                    return this.handleError(error, patchState);
            }));
    }

    @Action(PersonAction.DeletePersonActivity)
    deletePersonActivity({patchState , dispatch}: StateContext<PersonActivityStateModel>, {activityId}) {

        patchState({
            loading: true
        });
        return this.apiService.deletePersonActivity(activityId)
                    .pipe(
                        tap((res) => {
                            patchState({loading: false, message: res.data.deleteMedicalActivity.message});
                            if (res.data.deleteMedicalActivity.message) {
                                dispatch(new PersonAction.GetGeneralData());
                               const listRequestObj: ListRequest = {
                                pagination: {
                                  page: 1,
                                  items: 100
                                },
                                sort: 'createdAt',
                                sortOrder: Types.SortOrder.DESC
                              };
                              dispatch(new GetLists(listRequestObj));
                              this.people$.pipe(take(1)).subscribe(res=>{
                                    const usersReq = {
                                        search: '',
                                        pagination: res.peoplePagination,
                                        refinements: res.peopleRefinements,
                                        sort: 'createdAt',
                                        sortOrder: 'DESC'
                                    };
                                    dispatch(new GetUsersAction(usersReq));
                                })
                              this.utils.showSnackBar(res.data.deleteMedicalActivity.message, 5000);
                            }
                        })
                    ).pipe(catchError(error => this.handleError(error, patchState)));
    }

    @Action(PersonAction.ClearPersonActivitiesState)
    clearPersonActivity({patchState , getState}: StateContext<PersonActivityStateModel>) {
        patchState({
            loading: null,
            activities: [],
            err: null,
            message: null,
            userVaccDetail: null,
            userVaccCards: [],
            cardLoading: null,
            activitiesLoading: null
        });
    }


    @Action(PersonAction.GetUserVaccCards)
    getPersonVaccCards({patchState}: StateContext<PersonActivityStateModel> , {GetPVCD}) {
        patchState({ cardLoading: true});
        return this.apiService.getUserVaccCards(GetPVCD)
       .pipe(
           tap((res) => {
            patchState({ cardLoading: false,   userVaccCards: res.data.getUserVaccinationCards});
           })
       ).pipe(catchError(error => this.handleError(error, patchState)));
    }

    @Action(PersonAction.AddMultipleMedicalRecords)
    addMultipleMedicalRecords({patchState, getState , dispatch}: StateContext<PersonActivityStateModel>, { records}) {
        patchState({
            loading: true,
            err: false
        });
        return this.apiService.addMultipleMedicalRecords(records, getState().userId)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: PersonVaccCardRespObj  ) => {
                patchState({loading: false, cardLoading: true });
                dispatch(new PersonAction.GetGeneralData());
                this.utils.showSnackBar(res.data.addMultipleMedicalActivities.message, 5000);
            })
        );
    }

    @Action(PersonAction.GetPersonActivityLog)
    getPersonActivityLogs({patchState , getState}: StateContext<PersonActivityStateModel>, {request}) {
        patchState({
            loading: true,
            err: false
        });
        return this.apiService.getPersonActivityLogs(request)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: PersonActivityResponseObject  ) => {
                patchState({ activityLogs: res.data.getUserActivityLogs , loading: false});
            })
        );
    }
    @Action(PersonAction.ContactTracing)
    contactTeracing({patchState, getState}: StateContext<PersonActivityStateModel>, {contact, action}) {
        patchState({loading: true});
        let isDuplicate = false;
        const arr = cloneDeep(getState().contactTracing);
        if (action === contactTracingAction.ADD) {
           if (!_.find(arr, contact)) {
             arr.forEach( ele => {
                 if (ele.email === contact.email ) {
                    this.utils.showSnackBar('Contact with this Email already Exists!', 5000);
                    isDuplicate = true;
                 }   else if (ele.phone === contact.phone) {
                    this.utils.showSnackBar('Contact with this Phone number already Exists!', 5000);
                    isDuplicate = true;
                 }
             });
            if (!isDuplicate) {
                arr.push(contact);
            }
               patchState({contactTracing: arr , loading: false });
               return;
           }
           this.utils.showSnackBar('Contact Already Added!', 3000);
        } else if (action === contactTracingAction.REMOVE) {
            if (_.find(arr, contact)) {
                _.remove(arr, contact);
                patchState({contactTracing: arr });
                return;
            }
            this.utils.showSnackBar('Failed to reomve contact', 3000);
        } else if (action === contactTracingAction.UPDATE) {
            return;
        }
    }

    @Action(PersonAction.CreateContactTracingActivity)
    createContactTracingActivity({ patchState, getState , dispatch }: StateContext<PersonActivityStateModel>) {
        const users = getState().contactTracing;
        patchState({
            loading: true,
            err: false
        });
        return this.apiService.addMultipleContactTracing(users , getState().userId)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: PersonActivityResponseObject) => {
                this.utils.showSnackBar(res.data.addContactTracings.message, 5000);
                patchState({loading: false, contactTracing: []});
                dispatch(new PersonAction.GetGeneralData());
            })
        );
    }
    @Action(PersonAction.GetUserRecentContacts)
    getUsersRecentContacts({ patchState , getState}: StateContext<PersonActivityStateModel>) {
        const userId = getState().userId;

        patchState({
            loading: true,
            recentContact: null
        });
        return this.apiService.getUserRecentContacts(userId)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: PersonActivityResponseObject) => {

                patchState({loading: false, recentContact: res.data.getRecentContactTracing});
            })
        );
    }
    @Action(PersonAction.UpdateContacts)
    updateContacts({patchState, getState , dispatch }: StateContext<PersonActivityStateModel>) {
        patchState({loading: true});
        const contacts = getState().contactTracing;
        if (contacts && contacts.length > 0) {
            contacts.forEach( ele => {
                delete ele['formattedPhoneNumber'];
            });
        }
        return this.apiService.updateContactTracing(contacts, getState().MedicalId)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: PersonActivityResponseObject) => {
                this.utils.showSnackBar(res.data.updateContactTracings.message, 5000);
                patchState({loading: false, contactTracing: []});
                dispatch(new PersonAction.GetGeneralData());
            })
        );
    }
    @Action(PersonAction.SypmtomScreening)
    sypmtomScreening({patchState , dispatch }: StateContext<PersonActivityStateModel>, {request}) {
        patchState({loading: true});
        return this.apiService.symptomScreening(request)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: PersonActivityResponseObject) => {
                this.utils.showSnackBar(res.data.addMedicalActivity.message, 5000);
                patchState({loading: false});
                dispatch(new PersonAction.GetGeneralData());
            })
        );
    }
    @Action(PersonAction.ClearContacts)
    clearContacts({patchState}: StateContext<PersonActivityStateModel>) {
        patchState({
            contactTracing: []
        });
    }

    @Action(PersonAction.SetUpdate)
    setUpdateContacts({patchState, getState , dispatch }: StateContext<PersonActivityStateModel>) {
        patchState({
            MedicalId: getState().recentContact.id,
            contactTracing: []
        });

        const  arr = [];
        getState().recentContact.ContactTracings.forEach(ele => {
            arr.push(_.pick(ele.User, _.keys(model)));
        });
        patchState({contactTracing: arr , loading: false });

    }
    @Action(PersonAction.AddContactsToEdit)
    addContactsToEdit({ patchState }: StateContext<PersonActivityStateModel>, {contacts, medicalId})  {
        const  arr = [];
        contacts.forEach(ele => {
            arr.push(_.pick(ele.User, _.keys(model)));
        });
        patchState({
            MedicalId: medicalId,
            contactTracing: arr
        });
    }

}
