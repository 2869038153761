import { gql } from 'apollo-angular';

export const PreSignedUrlRequest = gql`
query getPreSignedUrl($fileName:String!,$fileEntity:FileEntity!) {
    getPreSignedUrl(fileName:$fileName,fileEntity:$fileEntity){
    url
    filePath
  }
}`;

export const ImportMembersRequest = gql`
  mutation importMembers($listName:String!,$filePath:String,$fileName: String,
    $fileSizeBytes: Int,$pointOfContact: String,$description: String!, $department: String, $sequences: [Int]) {
    importMembers(listName:$listName, filePath:$filePath, fileName:$fileName,fileSizeBytes:$fileSizeBytes,
      pointOfContact:$pointOfContact, description: $description, department:$department ,sequences:$sequences ){
        message
      }
    }
`;

// get users

export const getUsers = gql`
  query getUserList(
    $search: String,
    $pagination: Pagination,
    $refinements:UserRefinements,
    $sort: String,
    $sortOrder: SortOrder,
    ) {
    getUserList(
        search: $search,
        pagination: $pagination,
        refinements: $refinements
        sort: $sort,
        sortOrder: $sortOrder
      )
      {
        users {
          id,
          firstName,
          lastName,
          createdAt,
          areaCode,
          dateOfBirth,
          phone,
          email,
          activityDate,
          diagnosis,
          lastUpdatedDate,
          formattedPhoneNumber
        },
        pagination {
          totalRecords,
          totalPages,
          page
        }
      }
  }
`;

export const deleteUser = gql`
  mutation deleteAdminUser(
      $id: Int!
    ){
      deleteAdminUser(
        id: $id
        )
        {
          message
        }
    }
`;

export const USER_LOGS = gql`
  query GetUserLogs($userId: Int, $sort: String, $orderBy: SortOrder, $search: SearchUserLog ,$pagination: Pagination) {
    getUserLogs(userId: $userId, sort: $sort, orderBy: $orderBy, search: $search, pagination: $pagination) {
      logs {
        title
        createdAt
      }
      pagination {
        page
        totalPages
        totalRecords
      }
    }
  }
`;
