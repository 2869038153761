import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'formatNumber' })
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(number: string, areaCode?: string) {
    if(number && areaCode){
      return `${areaCode} (${number.slice(0,3)}) ${number.slice(3,6)}-${number.slice(6,10)}`
    } else if (number){
      return `(${number.slice(0,3)}) ${number.slice(3,6)}-${number.slice(6,10)}`
    }
  }
}
