import { TestResultFileAddInput, VaccinationCards, SingleImage  } from "app/shared/util/types"
import { ExportCSVRequest } from "./media.model"

export class MediaPresignedUrl {
    static readonly type = '[Media] Presigned Url'
    constructor(public url: string, public name: string, public file: Blob | File, public format: string) {}
}

export class UploadImage {
    static readonly type = '[Media] Upload Vaccination Card'
    constructor(public signedUrl: string, public file: Blob | File, public filePath?: string, public type?: string) {}
}

export class SetImage {
    static readonly type = '[Media] Set VC Attachments'
    constructor(public images: VaccinationCards|VaccinationCards[]|TestResultFileAddInput|TestResultFileAddInput[], public loadAll: boolean = false) {}
}

export class UploadSingle {
    static readonly type = '[Media] Upload Single Image'
    constructor(public imgObj: SingleImage) {}
}
export class ClearImages {
    static readonly type = '[Media] Clear VC Attachments'
}

export class DeleteImage {
    static readonly type = '[Media] Delete VC Image'
    constructor(public image: VaccinationCards|TestResultFileAddInput) {}
}

export class GetUserVaccinationCards{
    static readonly type = '[Media] Get User Vaccination Cards'
}

export class GetUserDashboardImages{
    static readonly type = '[Media] Get User Dashboard Images'
}

export class ExportCSV{
    static readonly type = '[Media] Export CSV'
    constructor(public reqObj: ExportCSVRequest) {}
}
export class ClearMedia{
    static readonly type = '[Media] Clear Media'
}