
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import * as UserRequest from './user.requests';
import * as UserModel from './user.model';
import { Observable } from 'rxjs';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
export enum LoginDevice {
  WEB = 0 ,
  IPHONE = 1,
  ANDRIOD = 2
}

@Injectable()
export class UserService {
    constructor(
      private apollo: Apollo,
      private jwtAuth: JwtAuthService
      ) {}

    signup(request: UserModel.SignupObj): Observable<UserModel.UserServiceResponse> {

        return this.apollo.mutate({
            mutation: UserRequest.SignupRequest,
            variables: {
                username: request.username,
                email: request.email,
                password: request.password,
                firstName: request.firstName,
                areaCode: request.areaCode,
                phone: request.phone,
                personalCode: request.personalCode,
                lastName: request.lastName
            },
            fetchPolicy: 'network-only'
          });
    }

    adminLogin(request: UserModel.AdminObj): Observable<UserModel.AdminServiceResponse> {

      return this.apollo.mutate({
        mutation: UserRequest.LoginRequest,
        variables: {
          email: request.email,
          password: request.password,
          loginIp: request.loginIp,
          loginDevice: LoginDevice[LoginDevice.WEB],
          appVersion: '13.0.0'

        }
      });
    }
    getOrganizations(): Observable<UserModel.GetOrgsServerResponse> {
      return this.apollo.query({
        query: UserRequest.getOrganizations,
        fetchPolicy: 'network-only'
      });
    }
    getOrg() {
     return  this.apollo.mutate({
        mutation: UserRequest.getOrganizations,
        fetchPolicy: 'network-only'
      });

    }

    forgetpassword(request: any) {
      return this.apollo.mutate({
          mutation: UserRequest.ForgetPasswordRequest,
          variables: request
        });
    }

    resetpassword(request: any) {
      return this.apollo.mutate({
          mutation: UserRequest.ResetPasswordRequest,
          variables: request
        });
    }

    getContextData(userId?: number): Observable<UserModel.ContextDataResObj> {
      return this.apollo.query({
        query: UserRequest.CONTEXTDATA,
        variables: userId,
        fetchPolicy: 'network-only'
      });
    }

    getUserData(): Observable<UserModel.UserDataResponse> {
      return this.apollo.query({
        query:  UserRequest.GETUSERDATA,
        fetchPolicy: 'network-only'
      });
    }

    // tslint:disable-next-line:max-line-length
    sendVerificationCode(request: UserModel.SendPhoneVerificationCodeRequestObject): Observable<UserModel.SendPhoneVerificationCodeResponseObject> {
      return this.apollo.mutate({
          mutation: UserRequest.sendPhoneVerificationCode,
          variables: request,
          fetchPolicy: 'network-only'
        });
    }

    verifyCode(request: UserModel.VerifyCodeRequestObject): Observable<UserModel.VerifyCodeResponseObject> {
      return this.apollo.mutate({
          mutation: UserRequest.verifyPhoneCode,
          variables: request
        });
    }
    refreshToken(refreshToken: string, expiredToken: string): Observable<UserModel.UserServiceResponse> {
      return this.apollo.query({
          query: UserRequest.GETNEWTOKENS,
          variables: {
              refreshToken,
              expiredAccessToken: expiredToken
          },
          fetchPolicy: 'network-only'
      });
    }

    changePassword(request): Observable<UserModel.UserDataResponse> {
      return this.apollo.mutate({
          mutation: UserRequest.changePassword,
          variables: request
        });
    }

    getBackendUsers(request: UserModel.BackendUsersRequest): Observable<UserModel.UserServiceResponse> {
      return this.apollo.query({
        query:  UserRequest.getBackendUsers,
        variables: request,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      });
    }
    deleteBackendUser(userId: number): Observable<UserModel.UserServiceResponse> {
      return this.apollo.mutate({
        mutation:  UserRequest.deleteAdminUser,
        variables: { id: userId}
      });
    }
    updateBackendUser(request: UserModel.UpdateAdminUserRequestObject): Observable<UserModel.UserServiceResponse> {
      return this.apollo.mutate({
        mutation:  UserRequest.updateAdminUser,
        variables: request
      });
    }
    resetBackendUserPassword(userId: number): Observable<UserModel.UserServiceResponse> {
      return this.apollo.mutate({
        mutation:  UserRequest.resetPassword,
        variables: { userId}
      });
    }
    getPrimaryAdmins(): Observable<UserModel.UserServiceResponse> {
      return this.apollo.query({
        query:  UserRequest.getPrimaryAdmins,
        fetchPolicy: 'network-only'
      });
    }
    updatePrimaryAdmin(userId: number): Observable<UserModel.UserServiceResponse> {
      return this.apollo.mutate({
        mutation:  UserRequest.updatePrimaryAdmin,
        variables: { organizationAdminId: userId}
      });
    }
    updateUserProfile(reqObj: UserModel.User): Observable<UserModel.UserServiceResponse> {
      return this.apollo.mutate({
        mutation:  UserRequest.updateUserProfile,
        variables: reqObj
      });
    }
    getInvitationTokenInfo(reqObj: UserModel.InvitationTokenReqObj): Observable<UserModel.UserServiceResponse> {
      return this.apollo.query({
        query:  UserRequest.GET_INVITATION_TOKEN_INFO,
        variables: reqObj,
        fetchPolicy: 'network-only'
      });
    }
    getUserRoleAndRight(): Observable<UserModel.UserServiceResponse> {
      return this.apollo.query({
        query: UserRequest.GET_USER_ROLE_RIGHTS,
        fetchPolicy: 'network-only'
      });
    }
}
