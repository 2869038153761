
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ListResponse, ListObj, BulkSequenceUpdateRequest, ListRequest, AddUserList, DeleteListObj } from './list.model';
import { ADD_USER_TOLIST, BulkSequenceUpdate, DELETE_LIST, EditListRequest, GetLists } from './list.requests';

@Injectable()
export class ListService {
    constructor(
      private apollo: Apollo,
      ){}

    editList(request: ListObj): Observable<ListResponse> {
      return this.apollo.mutate({
        mutation: EditListRequest,
        variables: request
      });
    }


    getLists(req?: ListRequest): Observable<ListResponse> {
      return this.apollo.query({
        query: GetLists,
        variables: req,
        fetchPolicy: 'network-only'
      });
    }  
    
    bulkSequenceUpdate(request: BulkSequenceUpdateRequest): Observable<ListResponse> {
      return this.apollo.mutate({
        mutation: BulkSequenceUpdate,
        variables: request
      });
    } 
    addUserToList(request: AddUserList): Observable<ListResponse>{
      return this.apollo.mutate({
        mutation: ADD_USER_TOLIST,
        variables: request
      })
    } 
    deleteList(request: DeleteListObj): Observable<ListResponse>{
      return this.apollo.mutate({
        mutation: DELETE_LIST,
        variables: request
      })
    } 
}