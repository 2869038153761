import { gql } from 'apollo-angular';

export const createTask = gql`
  mutation
    createTask(
      $assignedTo: Int!
      $workflowTitle: String!
      $instructions: String!
      $deadlineDate: String!
      $deadlineTime: String!
      $userIds: [Int]
      $allUsers: Boolean
      $filters: FilterInput
      $selectedGroupCount: Int
    )
    {
      createTask(
        assignedTo: $assignedTo,
        workflowTitle: $workflowTitle,
        instructions: $instructions,
        deadlineDate: $deadlineDate,
        deadlineTime:  $deadlineTime,
        userIds: $userIds,
        allUsers: $allUsers,
        filters: $filters
        selectedGroupCount: $selectedGroupCount
      )
      {
        message
      }
    }
`;

export const getTasks = gql`
  query
    getTaskList(
      $search: String,
      $sort: String,
      $sortOrder: SortOrder,
      $pagination: Pagination,
      $allTask: Boolean
    )
    {
      getTaskList(
        search: $search,
        sort: $sort,
        sortOrder: $sortOrder,
        pagination: $pagination,
        allTask: $allTask
      )
      {
        tasks {
          id,
          assignTo,
          workFlowTitle,
          instruction,
          deadLine,
          status,
            details
            
        },
        pagination {
          totalRecords,
          totalPages,
          page
        }
      }
    }
`;

export const getTaskDetail = gql`
    query GetTaskDetail($id: Int!, $pagination: Pagination) {
        getTaskDetail(id: $id, pagination: $pagination) {
            id
            assignTo
            workFlowTitle
            instruction
            deadLine
            status
            filters
            showCovidStatusColumn
            showScreeningStatusColumn
            members {
                taskMemberId
                userId
                firstName
                lastName
                phone
                email
                activityNotes
                outReach
                status
                areaCode
                updatedAt
                activityDate
                diagnosis
                screeningStatus
                formattedPhoneNumber
            }
            pagination {
                totalRecords
                totalPages
                page
            }
        }
    }
`;

export const updateTaskNotes = gql`
  mutation
    updateTaskNotes(
      $taskMemberId: Int!,
      $notes: String,
      $outreach: Outreach,
      $status: TaskMemberStatus
    )
    {
      updateTaskNotes(
        taskMemberId: $taskMemberId,
        notes: $notes,
        outreach: $outreach,
        status: $status
      )
      {
        message
      }
    }
`;
