import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() placeholderText: string;
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @ViewChild('searchInput')
  input: ElementRef;
  private subscription: Subscription;
  search = '';
  placeholder = 'Search by name , organization';

  ngOnInit(): void {
    if ( this.placeholderText ) { this.placeholder = this.placeholderText; }
  }

  searchByNameAndOrg() {
    this.onSearch.emit(this.search);
  }

  ngAfterViewInit(): void {
    const terms$ = fromEvent<any>(this.input.nativeElement, 'keyup')
         .pipe(
           map(event => event.target.value),
           debounceTime(400),
           distinctUntilChanged()
         );
      this.subscription = terms$
         .subscribe(
           criterion => {
             this.onSearch.emit(criterion);
           }
         );
   }
   ngOnDestroy() {
       this.subscription.unsubscribe();
     }

  keyupEvent(e){
    if(e.keyCode===13){
      this.onSearch.emit(this.search);
    }
  }

}

