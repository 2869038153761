import { InvitationStatus } from 'app/shared/util/types';
import * as OrgModel from './organization.model';
import {CreateDynamicTaskObj, TaskFilterRequest, TaskUpdateObj} from './organization.model';
// tslint:disable:max-classes-per-file
export class AddOrganization {
    static readonly type = '[Organization] Add Orrganization';
    constructor(public orgObj: OrgModel.OrganisationObj) {}
}

export class EditOrganization {
    static readonly type = '[Organization] Edit Orrganization';
    constructor(public orgObj: OrgModel.OrganisationObj) {}
}

export class DeleteOrganization {
    static readonly type = '[Organization] Delete Orrganization';
    constructor(public orgId: number) {}
}

export class GetOrganizations {
    static readonly type = '[Organization] Get Organizations';
    constructor(public reqObj?: OrgModel.GetOrganizationsRequest) {}
}
export class GetOrganization {
    static readonly type = '[Organization] Get Organization';
}
export class AddAdmin {
    static readonly type = '[Organization] Add Admin';
    constructor(public adminObj: OrgModel.AdminUserObj, public roles: any, public invitationStatus?: InvitationStatus[]) {}
}

export class GetAdmin {
    static readonly type = '[Organization] Get Organizations';
}
export class GetSequences {
    static readonly type = '[Organization] Get Sequences';
}
export class SetSelect {
    static readonly type = '[Organization] SET Select';
    constructor(public id: number) {}
}
export class SetOrgSeq {
    static readonly type = '[Organization] SET ORG SEQ';
    constructor(public id: number) {}
}
export class AdminAcceptDeclineInvite {
    static readonly type = '[ADMIN] UPDATE Invite';
    constructor(public inviteObj: OrgModel.AdminInviteObj) {}
}
export class UpdateOrganizationConfig {
    static readonly type = '[ADMIN] UPDATE Organization Config';
    constructor(public configObj: OrgModel.OrganizationConfig) {}
}
export class OrganizationOboardingStatus {
    static readonly type = '[ADMIN] Organization Onboarding Status';
}

export class ExportCSVAction {
    static readonly type = '[ADMIN] Organization export data';
}
export class GetListHistory {
    static readonly type = '[LIST] GetListHistory';
    constructor(public listId: number) {}
}
export class GetTimeZones {
    static readonly type = '[TimeZones] GetTimeZones';
}
export class PatchOrgSequence {
    static readonly type = '[Organization] Patch Org Sequences';
    constructor(public sequences: OrgModel.SequenceInput[]) {}
}
export class CreateDynamicTask {
    static readonly type = '[Organization] Create Dynamic Tasks';
    constructor(public task: CreateDynamicTaskObj) {}
}
export class GetDynamicTasks {
    static readonly type = '[Organization] Get Dynamic Tasks';
    constructor(public request?: TaskFilterRequest) {}
}

export class UpdateDynamicTask {
    static readonly type = '[Organization] Update Dynamic Tasks';
    constructor(public request: TaskUpdateObj) {}
}
export class DeleteDynamicTask {
    static readonly type = '[Organization] Delete Dynamic Tasks';
    constructor(public taskId: number) {}
}
export class GetGroupSize {
    static readonly type = '[Organization] Get Group Size';
    constructor(public dynamicTaskId: number) {}
}
