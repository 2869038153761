import { gql } from 'apollo-angular';

export const SignupRequest = gql`
mutation registerUser($username:String!,$email:String!,$password:String!,$firstName:String!,$areaCode:String!,$phone:String!,$personalCode:Int!,$lastName:String!) {
    registerUser(username:$username,email:$email,password:$password,firstName:$firstName,areaCode:$areaCode,phone:$phone,personalCode:$personalCode,lastName:$lastName){
    token
    user{
      email
      lastName
      firstName
      mobileVerified
    }
    roles {
      name
    }
  }
}`;


export const LoginRequest = gql`
mutation adminAppLogin($email:String!,$password:String!,$loginIp: String!,$loginDevice: LoginDevice!,$appVersion: String!) {
    adminAppLogin(email:$email,password:$password,loginIp:$loginIp ,loginDevice:$loginDevice ,appVersion: $appVersion ){
    token
    user{
      id
      firstName
      lastName
      email
      username
      areaCode
      phone
      mobileVerified
      emailVerified
      displayPhoneNumber
      roles {
        id,
        name
      }
      dateOfBirth
      timezone
      readVaccinationPolicy
      sendSmsNotifications
      isResetPassword
    }
    roles {
      id,
      name
    }
    organizationId
    refreshToken
    invitation
    {
      message,
      code
    }
  }
}`;

export const getOrganizations = gql`
query {
  getOrganizations{
    id
    name
  }
}`;

// forget password
export const ForgetPasswordRequest = gql`
  mutation forgotPassword($email: String!, $requestDevice: RequestDevice) {
    forgotPassword( email: $email ,requestDevice: $requestDevice ) {  message  }
  }
`;

export const ResetPasswordRequest = gql`
  mutation resetForgotPassword($forgotPasswordCode: String!, $password: String!) {
    resetForgotPassword(
      forgotPasswordCode: $forgotPasswordCode,
      password: $password
    ),
    {
      message
    }
  }
`;

export const CONTEXTDATA = gql`
query GetContentData($userId: Int) {
  getContentData(userId: $userId){
    logo
    activityNames
    whichTestsDidYouReceive
    documentTypes
    diagnosis
    vaccineManufacturers
    vaccines
    taskStatuses
    devices
    fileEntities
    phoneVerificationMediums
    operators
    sortOrders
    roles
    vaccineDoses
    areaCodes
    sequences {
      id
      name
      activities
      description
      sentEveryDays
      startingDateTime
      endingDateTime
      status
      organizationStatus
      color
      route
      isEligible
      message
    }
    everyXdays
    vaccinationActivityNames
    vaccinationFiltersValues
  }
}
`;

export const GETUSERDATA = gql`
query {
  getUserProfile{
    areaCode
    id
    isPrimaryAdmin
    isResetPassword
    firstName
    lastName
    phone
    dateOfBirth
    displayPhoneNumber
    areaCode
    email
    username
    mobileVerified
    emailVerified
    roles {
      name
      id
    }
    timezone
    readVaccinationPolicy
    sendSmsNotifications
    Invitations {
      id
      invitationCode
      Role {
        name
        id
      }
      status
    }
    organizationName
    formattedPhoneNumber
  }
}
`;

export const sendPhoneVerificationCode = gql`
  mutation
    sendPhoneVerificationCode(
      $areaCode: String!,
      $phone: String!,
      $medium: PhoneVerificationMedium!
    )
    {
      sendPhoneVerificationCode(
        areaCode: $areaCode,
        phone: $phone,
        medium: $medium
      )
      {
        message
      }
    }
`;

export const verifyPhoneCode = gql`
  mutation
    verifyPhoneVerificationCode(
      $areaCode: String!,
      $phone: String!,
      $phoneVerificationCode: String!
    )
    {
      verifyPhoneVerificationCode(
        areaCode: $areaCode,
        phone: $phone,
        phoneVerificationCode: $phoneVerificationCode
      )
      {
        message
      }
    }
`;
export const GETNEWTOKENS = gql`
query
  getNewAccessToken($refreshToken:String!,$expiredAccessToken:String! ){
    getNewAccessToken(refreshToken: $refreshToken, expiredAccessToken:$expiredAccessToken){
      token
      refreshToken
    }
  }
`;
export const changePassword = gql`
  mutation
  A(
      $oldPassword: String!,
      $password: String!
    )
    {
      changePassword(
        oldPassword: $oldPassword,
        password: $password
      )
      {
        message
      }
    }
`;

export const getBackendUsers = gql`
    query getBackendUsers(
        $search: String
        $pagination: Pagination
        $sort: String
        $orderBy: SortOrder
        $invitationStatus: [InvitationStatus]
        $rolesFilter: [Roles]
      )
      {
        getBackendUsers(
          search: $search
          pagination: $pagination
          sort: $sort
          orderBy: $orderBy
          invitationStatus: $invitationStatus
          rolesFilter: $rolesFilter
        )
        {
          users {
            id
            firstName
            lastName
            email
            username
            areaCode
            formattedPhoneNumber
            phone
            roles {
              id
              name
            }
            Invitations {
              id
              status
            }
            organizationName
          },
          pagination {
            totalRecords
            totalPages
            page
          }
        }
      }
`;

export const deleteAdminUser = gql`
  mutation deleteAdminUser(
      $id: Int!
    ){
      deleteAdminUser(
        id: $id
        )
        {
          message
        }
    }
`;

export const updateUserProfile = gql`
    mutation updateUserProfile(
        $username: String
        $firstName: String
        $lastName: String
        $areaCode: String
        $email: String
        $phone: String
        $dateOfBirth: String
        $timezone: String
        $readVaccinationPolicy: Boolean
        $sendSmsNotifications: Boolean
      )
      {
        updateUserProfile(
          username: $username
          firstName: $firstName
          lastName: $lastName
          areaCode: $areaCode
          email: $email
          phone: $phone
          dateOfBirth: $dateOfBirth
          timezone: $timezone
          readVaccinationPolicy: $readVaccinationPolicy
          sendSmsNotifications: $sendSmsNotifications
        )
        {
          message
        }
      }
`;

export const resetPassword = gql`
  mutation resetPassword(
      $userId: Int!
    )
    {
      resetPassword(
        userId: $userId
      )
      {
        message
      }
    }
`;

export const getPrimaryAdmins = gql`
  query{
    getOrganizationAdmins{
      id
      lastName
      firstName
      email
      isPrimaryAdmin

    }
  }
`;

export const updatePrimaryAdmin = gql`
  mutation updateOrganizationPrimaryAdmin(
      $organizationAdminId: Int!
    )
    {
      updateOrganizationPrimaryAdmin(
        organizationAdminId: $organizationAdminId
      )
      {
        message
      }
    }
`;
export const GET_INVITATION_TOKEN_INFO = gql`
query getInvitationTokenInfo($invitationCode: String!){
  getInvitationTokenInfo(invitationCode:$invitationCode){
    email
  }
}
`;
export const GET_USER_ROLE_RIGHTS = gql`
query{
  getUserRoleAndRights{
    role{
      orgAdmin
      superAdmin
      seniorManager
      juniorManager
      member
      generalUser
    }
    userRights
  }
}
`;

export const updateAdminUser = gql`
  mutation UpdateAdminUser($updateAdminUserId: Int!, $firstName: String!, $lastName: String!, $areaCode: String!, $phone: String!, $email: String) {
    updateAdminUser(id: $updateAdminUserId, firstName: $firstName, lastName: $lastName, areaCode: $areaCode, phone: $phone, email: $email) {
      message
    }
  }
`;
