import {
    TaskWorkflowCreateObject,
    TaskWorkflowListRequestObject,
    TaskDetailRequestObject,
    TaskNotesUpdateRequestObject,
    GetTaskDetailsReqObj
} from './task-workflow.model';

export class CreateTaskWorkflow {
    static readonly type = '[TASKWORKFLOW] Create'
    constructor(public taskWorkflowObject: TaskWorkflowCreateObject) {}
}

export class GetTaskWorkflows {
    static readonly type = '[TASKWORKFLOW] GetTaskWorkflows'
    constructor(public taskWorkflowListRequestObject: TaskWorkflowListRequestObject) {}
}

export class GetMyTaskWorkflows {
    static readonly type = '[TASKWORKFLOW] GetTaskMyWorkflows'
    constructor(public taskWorkflowListRequestObject: TaskWorkflowListRequestObject) {}
}

export class GetTaskDetail {
    static readonly type = '[TASKWORKFLOW] GetTaskDetail'
    constructor(public request: GetTaskDetailsReqObj) {}
}

export class UpdateTaskNotes {
    static readonly type = '[TASKWORKFLOW] UpdateTaskNotes'
    constructor(public taskNotesRequestObject: TaskNotesUpdateRequestObject) {}
}
