
import { Injectable } from "@angular/core";
import { Selector } from '@ngxs/store'
import { GeneralStateModel } from "./general-store.model";
import { GeneralState } from "./general-store.state";


@Injectable()
export class GeneralStoreSelector {
    @Selector([GeneralState])
    static getGeneratState(state: GeneralStateModel) {
        return state;
    }
    @Selector([GeneralState])
    static getContentData(state: GeneralStateModel) {
        return state.ContentData;
    }
    @Selector([GeneralState])
    static getOTPStatus(state: GeneralStateModel) {
        return state.sessionOTPVerified;
    }
}
