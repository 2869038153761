import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { BulkSequenceUpdateRequest, ListRequest, ListResponse, ListStateModel } from './list.model';
import { AddUserToList, BulkSequenceUpdate, CreateTaskWorkFlow, DeleteList, EditList, GetLists, ShowHideMultiEdit } from './list.action';
import { ListService } from './list.service';
import { SortOrder } from 'app/shared/util/types';
import * as _ from "lodash";
import { UtilService } from 'app/shared/services/utils.service';

@State<ListStateModel>({
    name: 'List',
    defaults: {
        list: null,
        success: null,
        error: null,
        pagination: {
            totalRecords: 0,
            totalPages: 0,
            page: 1,

        },
        editListLoading: null,
        listLoading: null,
        taskPeople: [],
        taskBtn: true,
        SelectedList: []
    }
})

@Injectable()
export class ListState {

    constructor(
        private apiService: ListService,
       private utils : UtilService ,
    ) {}
   
    private handleError(error: string, patchState: any = null){
        this.utils.showSnackBar(error) 
        if(patchState)
            patchState({error: true})
        return [];
    }
    
    @Action(EditList)
    editList({ patchState, dispatch  }: StateContext<ListStateModel> , {listObj}) {
      
        patchState({success: false ,error: false, editListLoading: true})
        return this.apiService.editList(listObj)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: ListResponse) => {
                this.utils.showSnackBar(res.data.updateList.message)
                patchState({success: true})
                const listRequest: ListRequest = {
                    pagination: {
                        page: 1,
                        items: 10
                    },
                    sort: 'createdAt',
                    sortOrder: SortOrder.DESC
                }
                dispatch(new GetLists(listRequest))
            })
        );
    }

    @Action(GetLists)
    getLists({ patchState }: StateContext<ListStateModel>, {listReq}) {
        patchState({listLoading: true})
        return this.apiService.getLists(listReq)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: ListResponse) => {
                patchState({list: res.data.getImportList.lists, pagination: res.data.getImportList.pagination, listLoading: false});
            })
        );
    }

    @Action(BulkSequenceUpdate)
    bulkSequenceUpdate({ patchState,dispatch , getState }: StateContext<ListStateModel>, {sequenceIds}) {
        patchState({error: false, success: false})
        let ids  = _.map(getState().SelectedList, 'id');
        let bulkSeq: BulkSequenceUpdateRequest = {
            sequences:  sequenceIds,
            listIds: ids
          }
        return this.apiService.bulkSequenceUpdate(bulkSeq)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: ListResponse) => {
                this.utils.showSnackBar(res.data.bulkSequenceUpdate.message)
                const listRequest: ListRequest = {
                    pagination: {
                        page: 1,
                        items: 10
                    },
                    sort: 'createdAt',
                    sortOrder: SortOrder.DESC
                }
                dispatch(new GetLists(listRequest))
                patchState({success: true});
            })
        );
    }

    @Action(ShowHideMultiEdit)
    showEditHideButton({ patchState}: StateContext<ListStateModel>, {show,selectedList}) {
        patchState({ showMultiEdit: show , SelectedList: selectedList })
    }
    @Action(CreateTaskWorkFlow)
    createTaskWorkFlow({ patchState}: StateContext<ListStateModel>, {people}) {
        if(people.length > 0){
            patchState({ taskPeople: people , taskBtn: false})
        } else if (people.length === 0){
            patchState({taskBtn: true})
        }
    }
    @Action(AddUserToList)
    addUserToList({ patchState , dispatch}: StateContext<ListStateModel>, {userObj}) {
        patchState({error: false, success: false})
        return this.apiService.addUserToList(userObj)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: ListResponse) => {
                this.utils.showSnackBar(res.data.addUserToList.message)
                let obj: ListRequest = {
                    pagination : {
                      page: 1,
                      items: 100
                    },
                    sort: 'createdAt',
                    sortOrder: SortOrder.DESC
                  }
                 dispatch(new GetLists(obj))
                patchState({success: true});
            })
        );
    }
    @Action(DeleteList)
    deleteList({ patchState,dispatch}: StateContext<ListStateModel>, {listObj}) {
        patchState({error: false, success: false})
        return this.apiService.deleteList(listObj)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: ListResponse) => {
                this.utils.showSnackBar(res.data.deleteList.message)
                let lists: ListRequest = {
                    pagination : {
                      page: 1,
                      items: 100
                    },
                    sort: 'createdAt',
                    sortOrder: SortOrder.DESC
                  }
                  dispatch(new GetLists(lists))
                patchState({success: true});
            })
        );
    }
}