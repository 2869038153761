import { Injectable } from '@angular/core';
import { LocalStoreService } from '../local-store.service';
import { Router, ActivatedRoute } from '@angular/router';
import { map, catchError, delay, skipWhile, take } from 'rxjs/operators';
import { User } from 'app/store/users-store/user.model';
import { of, throwError, Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { UserSelectors } from 'app/store/users-store/user.selectors';
import { UserStateModal } from '../../../store/users-store/user.model';
import { RolesId } from 'app/shared/util/types';
import * as _ from 'lodash';
import { StateClear } from 'ngxs-reset-plugin';
@Injectable({
  providedIn: 'root',
})
export class JwtAuthService {
  token;
  isAuthenticated: boolean;
  signingIn: boolean;
  return: string;
  JWT_TOKEN = 'JWT_TOKEN';
  REF_TOKEN = 'REF_TOKEN';
  USER_ROLE = 'USER_ROLE';
  @Select(UserSelectors.getUserData) currUser$: Observable<User>;
  @Select(UserSelectors.getUserOrganization) currUserOrg$: Observable<UserStateModal>;
  @Select(UserSelectors.getPermissions) permissions$: Observable<UserStateModal>;

  constructor(
    private ls: LocalStoreService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) {
    this.route.queryParams.subscribe((params) => {
      this.return = params['return'] || '/';
    });
  }

  public signin(token, role) {
    return of({ token, role }).pipe(
      delay(1000),
      map((res: any) => {
        this.setUserAndToken(res.token, !!res);
        this.signingIn = false;
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public checkTokenIsValid() {
    return of().pipe(
      map((profile: User) => {
        this.setUserAndToken(this.getJwtToken(), true);
        this.signingIn = false;
        return profile;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  public signout() {
    this.dispatchClearStateAction();
    this.setUserAndToken(null, false);
    this.ls.clear();
    this.router.navigateByUrl('sessions/signin');
  }

  public dispatchClearStateAction() {
    this.store.dispatch(new StateClear());
  }

  isLoggedIn(): boolean {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
  getRefToken() {
    return this.ls.getItem(this.REF_TOKEN);
  }

  getRole(): Promise<any> {
   const   pRole =  this.ls.getItem('PRIOTY_ROLE');

    return new Promise((resolve, reject) => {
      if(pRole){
        resolve( parseInt(pRole))
      }
      this.currUser$
        .pipe(skipWhile((res) => !res))
        .pipe(take(1))
        .subscribe((res) => {
          if (_.includes(_.map(res.roles, 'id'), RolesId.SUPER_ADMIN)) {
            resolve(RolesId.SUPER_ADMIN);
          }
          if (_.includes(_.map(res.roles, 'id'), RolesId.ORG_ADMIN)) {
            resolve(RolesId.ORG_ADMIN);
          }
          if (_.includes(_.map(res.roles, 'id'), RolesId.SENIOR_MANAGER)) {
            resolve(RolesId.SENIOR_MANAGER);
          }
          if (_.includes(_.map(res.roles, 'id'), RolesId.JUNIOR_MANAGER)) {
            resolve(RolesId.JUNIOR_MANAGER);
          }
          if (_.includes(_.map(res.roles, 'id'), RolesId.MEMBER)) {
            resolve(RolesId.MEMBER);
          }
          reject(-1);
        });
    });
  }

  getPermissions(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.permissions$
        .pipe(skipWhile((res) => !res))
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            const permissions = Object.keys(res).filter((key) => {
              if ( res[key] === true ) { return key; }
            });
            resolve(permissions);
          }
          reject([]);
        });
    });
  }

  getRoleIds(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.currUser$
        .pipe(skipWhile((res) => !res))
        .pipe(take(1))
        .subscribe((res) => {
          resolve(res.roles);
        });
    });
  }

  getUser() {
    return this.currUser$;
  }

  getUserOrganization() {
    return this.currUserOrg$;
  }

  async isSuperAdmin() {
    const role = await this.getRoleIds();
    return _.includes(_.map(role, 'id'), RolesId.SUPER_ADMIN);
  }

  async isSeniorManager() {
    const role = await this.getRoleIds();
    return _.includes(_.map(role, 'id'), RolesId.SENIOR_MANAGER);
  }

  async isJuniorManager() {
    const role = await this.getRoleIds();
    return _.includes(_.map(role, 'id'), RolesId.JUNIOR_MANAGER);
  }
  async isOrgAdmin() {
    const role = await this.getRoleIds();
    return _.includes(_.map(role, 'id'), RolesId.ORG_ADMIN);
  }
  async isEmpolyee() {
    const role = await this.getRoleIds();
    return _.includes(_.map(role, 'id'), RolesId.MEMBER);
  }
  setUserAndToken(token: string, isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.ls.setItem(this.JWT_TOKEN, token);
  }

  setUserToken(token: string) {
    this.ls.setItem(this.JWT_TOKEN, token);
  }
  setUserRefreshToken(token: string) {
    this.ls.setItem(this.REF_TOKEN, token);
  }

  clearLS() {
    this.ls.clear();
  }
}
