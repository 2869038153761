import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedMaterialModule} from '../shared-material.module';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {SharedPipesModule} from '../pipes/shared-pipes.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HeaderSideComponent} from './header-side/header-side.component';
import {SidebarSideComponent} from './sidebar-side/sidebar-side.component';
import {HeaderTopComponent} from './header-top/header-top.component';
import {SidebarTopComponent} from './sidebar-top/sidebar-top.component';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {SidenavComponent} from './sidenav/sidenav.component';
import {FooterComponent} from './footer/footer.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {AppComfirmComponent} from '../services/app-confirm/app-confirm.component';
import {AppLoaderComponent} from '../services/app-loader/app-loader.component';
import {EgretSidebarComponent, EgretSidebarTogglerDirective} from './egret-sidebar/egret-sidebar.component';
import {ActivityLogComponent} from './activity-log/activity-log.component';
import {FilterChipComponent} from './filter-chip/filter-chip.component';
import {SearchComponent} from './search/search.component';

import {MatFormFieldModule} from '@angular/material/form-field';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {ToastComponent} from './toast/toast.component';


const components = [
    HeaderTopComponent,
    SidebarTopComponent,
    SidenavComponent,
    NotificationsComponent,
    SidebarSideComponent,
    HeaderSideComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    BreadcrumbComponent,
    AppComfirmComponent,
    AppLoaderComponent,
    EgretSidebarComponent,
    FooterComponent,
    EgretSidebarTogglerDirective,
    ActivityLogComponent,
    FilterChipComponent,
    SearchComponent,
    ToastComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        FlexLayoutModule,
        PerfectScrollbarModule,
        SharedPipesModule,
        SharedMaterialModule,
        NgxMatSelectSearchModule,
        MatFormFieldModule,
        ReactiveFormsModule
    ],
    declarations: components,
    exports: components
})
export class SharedComponentsModule {
}
