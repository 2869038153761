import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
 providedIn: 'root'
})
export class ToastService {

    private toastConfig = new BehaviorSubject({
        // visible: false,
        // message: '',
        // showButton: true,
        // buttonText: 'Logout',
        // duration: 10000
    });
    currentToastConfig = this.toastConfig.asObservable();

    constructor() {
    }

    updateToastConfig(config: any) {
        this.toastConfig.next(config)
    }
}