import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MediaServiceResponse, MediaStateModal } from './media.model';
import { ClearImages, ClearMedia, DeleteImage, ExportCSV, GetUserDashboardImages, GetUserVaccinationCards, MediaPresignedUrl, SetImage, UploadImage, UploadSingle } from './media.actions';
import { MediaService } from './media.service';
import { FileEntity } from 'app/shared/util/types';
import { cloneDeep } from '@apollo/client/utilities';
import { UtilService } from 'app/shared/services/utils.service';
@State<MediaStateModal>({
    name: 'Media',
    defaults: {
        presignedUrl: undefined,
        loading: null,
        Attachments: [],
        isUploaded: null,
        OrgLogo: null,
        isPresigned: null
    }
})

@Injectable()
export class MediaState {

    constructor(private apollo: Apollo, private utils: UtilService , private apiService: MediaService, private router: Router) {}

    private handleError(error: string, patchState: any = null){
        this.utils.showSnackBar(error) 
        if(patchState)
            patchState({loading: false})
        return [];
    }

    @Action(SetImage)
    setImages({ patchState, getState, dispatch }: StateContext<MediaStateModal> , {images, loadAll}) {
        if(loadAll){
            patchState({Attachments: images})
        }
        else{
            if(images.documentType==FileEntity.LOGO) {
                patchState({OrgLogo: images})
                dispatch(new MediaPresignedUrl(new Date().getTime().toString()+"."+images.fileExtension,images.documentType,images.imgBlob,images.fileExtension));
            }
            else{
                const index = getState().Attachments.findIndex(file => file.uri == images.uri)
                if(index<0){
                    patchState({Attachments: [...getState().Attachments,images]})
                    dispatch(new MediaPresignedUrl(new Date().getTime().toString()+"."+images.fileExtension,images.documentType,images.imgBlob,images.fileExtension))
                }
            }
        }
    }
    @Action(UploadSingle)
    uploadSingle({ patchState , dispatch}:  StateContext<MediaStateModal>, {imgObj } ){
        dispatch( new ClearImages())
        patchState({isPresigned: false, loading: true});
        return this.apiService.getUrl(imgObj.fileName,imgObj.fileEntity)
        .pipe(catchError(error => this.handleError(error)))
        .pipe(
            tap((res) => {
                patchState({presignedUrl: {filePath: res.data.getPreSignedUrl.filePath, url: res.data.getPreSignedUrl.url }, isPresigned: true , loading: false});
                dispatch(new UploadImage(res.data.getPreSignedUrl.url,imgObj.file,res.data.getPreSignedUrl.filePath,imgObj.fileName))
            })
        );
    }
    @Action(MediaPresignedUrl)
    getMediaPresignedUrl({ patchState, dispatch }: StateContext<MediaStateModal> , {url,name,file}) {
        patchState({isPresigned: false});
        return this.apiService.getUrl(url,name)
        .pipe(catchError(error => this.handleError(error)))
        .pipe(
            tap((res) => {
                patchState({presignedUrl: {filePath: res.data.getPreSignedUrl.filePath, url: res.data.getPreSignedUrl.url }, isPresigned: true});
                dispatch(new UploadImage(res.data.getPreSignedUrl.url,file,res.data.getPreSignedUrl.filePath,name))
            })
        );
    }

    @Action(UploadImage)
    uploadImage({ patchState, getState }: StateContext<MediaStateModal> , {signedUrl,file,filePath,type}) {

        patchState({loading: true})
        return this.apiService.uploadFile(signedUrl,file)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res) => {
                if(res.status==201){
                    if(type==FileEntity.LOGO){
                        let attachments = cloneDeep(getState().OrgLogo);
                        attachments.filePath=filePath;
                        patchState({presignedUrl:undefined, isUploaded: true,  OrgLogo: attachments, loading: false});
                    }
                    else{
                        patchState({isUploaded: true, loading: false});
                    }
                }
            })
        );
    }

    @Action(ClearImages)
    clearImages({patchState}:StateContext<MediaStateModal>){
        patchState({Attachments: [], OrgLogo: undefined , presignedUrl: null, loading: null , isUploaded: null, isPresigned: null});
    }

    @Action(DeleteImage)
    deleteImage({patchState,getState}:StateContext<MediaStateModal>,{image}){
        const index = getState().Attachments.findIndex(file => file.fileName == image.fileName)
        if(index > -1)
        {
            let _attachments = cloneDeep(getState().Attachments);
            _attachments.splice(index,1);
            patchState({Attachments: _attachments});
        }
    }

    @Action(GetUserVaccinationCards)
    getUserVaccinationCards({ patchState }: StateContext<MediaStateModal>) {
        patchState({loading: true});
        return this.apiService.getUserVCCards()
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: MediaServiceResponse) => {
                patchState({loading: false, Attachments: res.data.getUserVaccinationCards});
            })
        );
    }

    @Action(GetUserDashboardImages)
    getUserDashboarddImages({ patchState }: StateContext<MediaStateModal>){
        patchState({loading: true});
        return this.apiService.getDashboardImages()
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: MediaServiceResponse) => {
                patchState({loading: false, DashboardImages: res.data.getUserVaccinationCards});
            })
        );
    }

    @Action(ExportCSV)
    exportCSV({ patchState }: StateContext<MediaStateModal>, {reqObj}){
        return this.apiService.exportCSV(reqObj)
        .pipe(catchError(error => this.handleError(error)))
        .pipe(
            tap((res: MediaServiceResponse) => {
                this.utils.showSnackBar(res.data.exportCsv.message, 5000)
            })
        );
    }
    @Action(ClearMedia)
    clearMedia({patchState} : StateContext<MediaStateModal>){
        patchState({OrgLogo: null})
    }

}
