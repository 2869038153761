import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store, Select } from "@ngxs/store";
import { MatProgressBar } from "@angular/material/progress-bar";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { GetPersonActivityLog } from "app/store/person-activity-store/person-activity.action";
import { PersonActivitySelectors } from "app/store/person-activity-store/person-activity.selectors";
import { Observable, Subscription } from "rxjs";
import { PersonActivityLogsObj, PersonActivityStateModel } from "app/store/person-activity-store/person-activity.model";
import { skipWhile, take } from "rxjs/operators";
import { Pagination } from "app/shared/util/types";


@Component({
  selector: "app-activity-log-popup",
  templateUrl: "./activity-log.component.html",
  styleUrls: ["./activity-log.component.scss"],
})

export class ActivityLogComponent implements OnInit {
  

  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  @Select(PersonActivitySelectors.personActivityLogs) activities$ : Observable<PersonActivityStateModel>

  @ViewChild('searchInput') 
  input: ElementRef;
  private subscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    
    public dialogRef: MatDialogRef<ActivityLogComponent>,
    public store: Store,
    public loader: AppLoaderService,
  ) {
   
  }
  vacc = false
  test = false
  screening = false
  tracing = false
  items;
  temp ;

  itemsPerPage = ['5'];
  size = '5';
  pagination: Pagination = {
    page: 1,
    items: 5,
  };
  
  ngOnInit() {
    this.getPersonActivityLog();
  }
  
  ngAfterViewChecked():void{
    window.dispatchEvent(new Event('resize'));
  }

  getPersonActivityLog(search?){
    let req: PersonActivityLogsObj = {
      userId: this.data.user.id,
      search: {
        activityName: search ? search : ''
      },
      pagination: {
        page: this.pagination.page,
        items: this.pagination.items
      },
      orderBy: 'DESC',
      sort: 'activityDate'
    }
    this.store.dispatch(new GetPersonActivityLog(req))  

  }

  close() {
    this.dialogRef.close();
  }

  search(event){
    this.getPersonActivityLog(event.target.value.trim())
  }


  updateItemsPerPage(event) {
    this.size = event.pageSize;
    this.pagination.page = event.pageIndex + 1;
    this.pagination.items = parseInt(this.size);
    this.getPersonActivityLog();
  }
}