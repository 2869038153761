<div class="main">

  <div class="tdiv">
    <h1  class="title">{{ data.title }}</h1>
    <mat-icon class='close-icon' (click)="dialogRef.close()" removeIcon > clear</mat-icon>

  </div>
  <h4 *ngIf="data.key"  class="message mb-16"  >{{ data.message }}<strong> {{data.key}} ? </strong> </h4>
  <h4 *ngIf="!data.key"  class="message mb-24"  >{{ data.message }} </h4>
  <div *ngIf="!data.closeBtn" class="btns"  >
      <button 
      class="delete-btn"
      type="button"
      mat-raised-button
      (click)="dialogRef.close(true)"
      >
      {{ data?.btnName ? data?.btnName : "Delete" }}
    </button>
    
    <button  type="button" class="cancel-btn" mat-raised-button (click)="dialogRef.close(false)">
      Cancel
    </button>
  </div>
  <div *ngIf="data.closeBtn" class="btns"  >

    <button  type="button" class="close-btn" mat-raised-button (click)="dialogRef.close(false)">
    {{ data.btnName }}
    </button>
  </div>
</div>

