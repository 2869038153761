import { State, Action, StateContext, Store, Select } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { catchError, take, tap } from 'rxjs/operators';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import {  GetOrganizationsRequest, OrganisationResponse, OrganizationStateModal, TaskFilterRequest } from './organization.model';
import {
    AddAdmin,
    AddOrganization,
    AdminAcceptDeclineInvite,
    CreateDynamicTask, DeleteDynamicTask,
    DeleteOrganization,
    EditOrganization,
    ExportCSVAction, GetDynamicTasks, GetGroupSize,
    GetListHistory,
    GetOrganization,
    GetOrganizations,
    GetSequences,
    GetTimeZones,
    OrganizationOboardingStatus,
    PatchOrgSequence,
    SetOrgSeq,
    SetSelect, UpdateDynamicTask,
    UpdateOrganizationConfig
} from './organization.action';
import { OrganizationService } from './organization.service';
import { GetBackendUsers, GetContextDataAction } from '../users-store/user.action';
import { cloneDeep } from '@apollo/client/utilities';
import { MediaStateSelectors } from '../mediaStore/media.selectors';
import { Observable } from 'rxjs';
import { ClearImages } from '../mediaStore/media.actions';
import { BackendUsersRequest } from '../users-store/user.model';
import { SortOrder } from 'app/shared/util/types';
import { UtilService } from 'app/shared/services/utils.service';

@State<OrganizationStateModal>({
    name: 'Organization',
    defaults: {
        organisationsList: [],
        dynamicTaskList: [],
        pagination: {
            totalPages: 1,
            totalRecords: 0,
            page: 1
        },
        organization: null,
        success: null,
        error: null,
        activeSequences: null,
        OrgSequences: null,
        Sequences: null,
        message: '',
        loading: null,
        onboardingDeatils: null,
        listHistory: [],
        groupSize: []
    }
})

@Injectable()
export class OrganizationState {
    @Select(MediaStateSelectors.getOrgLogo) orgLogo$: Observable<any>;
    constructor(
        private apollo: Apollo,
        private store: Store ,
        private apiService: OrganizationService,
        private authService: JwtAuthService,
        private utils: UtilService ,
    ) {}

    private handleError(error: string, patchState: any = null) {
        this.utils.showSnackBar(error);
        if (patchState) {
            patchState({error: true, loading: false});
        }
        return [];
    }

    @Action(AddOrganization)
    addOrganization({patchState, dispatch , getState }: StateContext<OrganizationStateModal> , {orgObj}) {
        let filePath = '';
        this.orgLogo$.pipe(take(1)).subscribe(res => {
            if (res?.filePath) {
                filePath = res.filePath.split('?')[0];
            }
        });
        if (filePath) {
            orgObj.logo = filePath;
        }
        patchState({success: false , error: false});
        const sequences = getState().Sequences.map(item => {
            const seq = cloneDeep(item);
            seq.startingDateTime = null;
            seq.endingDateTime = null;
            seq.sentEveryDays = null;
            return seq;
        });
        orgObj.sequences = sequences;
        return this.apiService.addOrganization(orgObj)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: OrganisationResponse) => {
                this.utils.showSnackBar(res.data.addOrganization.message);
                patchState({success: true});
                dispatch(new ClearImages());
                const obj: GetOrganizationsRequest = {
                    sort: 'createdAt',
                    pagination: {
                        page: 1,
                        items: 10
                    }
                };
                dispatch(new GetOrganizations(obj));
            })
        );
    }

    @Action(EditOrganization)
    editOrganization({ patchState, dispatch }: StateContext<OrganizationStateModal> , {orgObj}) {
        let filePath = orgObj.logo;
        this.orgLogo$.pipe(take(1)).subscribe(res => {
            if (res?.filePath) {
                filePath = res.filePath;
            }
        });
        if (filePath) { orgObj.logo = filePath.split('?')[0]; }

        patchState({success: false , error: false});
        return this.apiService.editOrganization(orgObj)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap(async (res: OrganisationResponse) => {
                this.utils.showSnackBar(res.data.updateOrganization.message);
                patchState({success: true});
                dispatch(new ClearImages());
                const obj: GetOrganizationsRequest = {
                    sort: 'createdAt',
                    sortOrder: SortOrder.DESC,
                    pagination: {
                        page: 1,
                        items: 10
                    }
                };
                if (await this.authService.isSuperAdmin()) {

                    dispatch(new GetOrganizations(obj));
                } else {
                    dispatch(new GetOrganization());
                    dispatch(new GetContextDataAction());
                }
            })
        );
    }

    @Action(DeleteOrganization)
    deleteOrganization({  patchState, dispatch }: StateContext<OrganizationStateModal> , {orgId}) {
        patchState({success: false , error: false});
        return this.apiService.deleteOrganization(orgId)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: OrganisationResponse) => {
                this.utils.showSnackBar(res.data.deleteOrganization.message);
                patchState({success: true});
                dispatch(new GetOrganizations());
            })
        );
    }

    @Action(GetOrganizations)
    getOrganizations({ patchState }: StateContext<OrganizationStateModal>, {reqObj}) {
        patchState({loading: true});
        return this.apiService.getOrganizations(reqObj)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: OrganisationResponse) => {
                patchState({
                    organisationsList: res.data.getOrganizations.organizations,
                    pagination: res.data.getOrganizations.pagination,
                    loading: false
                });
            })
        );
    }

    @Action(GetOrganization)
    getOrganization({ patchState }: StateContext<OrganizationStateModal>) {
        patchState({loading: true});
        return this.apiService.getOrganization()
        .pipe(catchError( error => this.handleError(error, patchState)
         ))
        .pipe(
            tap((res: OrganisationResponse) => {
                const _organization = cloneDeep(res.data.getOrganization);
                let seq = _organization.sequences;
                seq = seq.filter(_res => _res.status);
                _organization.sequences = seq;
                const orgActiveSeq = seq.filter(_res => _res.organizationStatus);
                patchState({organization: _organization, loading: false, activeSequences: orgActiveSeq});
            })
        );
    }


    @Action(AddAdmin)
    addAdmin({ patchState, dispatch }: StateContext<OrganizationStateModal> , {adminObj, roles, invitationStatus}) {
        patchState({success: false , error: false});
        return this.apiService.addAdmin(adminObj)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: OrganisationResponse) => {
                this.utils.showSnackBar(res.data.inviteBackendUser.message);
                patchState({success: true});
                const usersRequestObj: BackendUsersRequest = {
                    rolesFilter: roles,
                    invitationStatus,
                    sort: 'createdAt',
                    orderBy: SortOrder.DESC
                };
                dispatch(new GetBackendUsers(usersRequestObj));
            })
        );
    }
    @Action(GetSequences)
    getSeq({ patchState}: StateContext<OrganizationStateModal>) {
        return this.apiService.getSequences()
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: OrganisationResponse) => {
               const sequences = cloneDeep(res.data.getSequences);
               sequences.forEach( ele => {
                   ele.status = false;
                   if ( !ele.description ) { ele.description = ''; }
               });
                patchState({Sequences: sequences});
            })
        );
    }
    @Action(SetSelect)
    setSelect({ patchState , getState}: StateContext<OrganizationStateModal> , {id}) {
        const state = getState().Sequences;
            state.forEach( ele => {
                if (ele.id === id) {
                    ele.status = !ele.status;
                    ele.organizationStatus = !ele.organizationStatus;
                }
            });
            patchState({ Sequences: state});

    }
    @Action(SetOrgSeq)
    setOrgSeq({ patchState , getState}: StateContext<OrganizationStateModal> , {id}) {
        const state = getState().Sequences;
            state.forEach( ele => {
                if (ele.id === id) {
                    ele.selected = true;
                }
            });
            patchState({ OrgSequences: state});

    }

    @Action(PatchOrgSequence)
    patchOrgSeq({ patchState , getState}: StateContext<OrganizationStateModal> , {sequences}) {
        const organization = getState().organization;
        organization.sequences = sequences;
        patchState({
            organization
        });
    }

    @Action(AdminAcceptDeclineInvite)
    adminInviteAcceptDecline({ patchState}: StateContext<OrganizationStateModal> , {inviteObj}) {
        patchState({ loading: true});
        return this.apiService.adminAcceptDeclineInvite(inviteObj)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: OrganisationResponse) => {
                patchState({ loading: false, message: res.data.acceptDeclineAdminManagerInvitation.message});
            })
        );
    }
    @Action(UpdateOrganizationConfig)
    updateOrganizzationConfig({dispatch, patchState}: StateContext<OrganizationStateModal>, {configObj}) {
        return this.apiService.updateOrganizationConfig(configObj)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: OrganisationResponse) => {
                this.utils.showSnackBar(res.data.updateOrganizationConfig.message);
                dispatch(new GetOrganization());
            })
        );
    }

    @Action(OrganizationOboardingStatus)
    OrganizationOboardingStatus({patchState}: StateContext<OrganizationStateModal>) {
        return this.apiService.getOrganizationOnboardingDetails()
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: OrganisationResponse) => {
                patchState({onboardingDeatils: res.data.organizationOnboardingStatus});
            })
        );
    }

    @Action(ExportCSVAction)
    exportCSV({patchState}: StateContext<OrganizationStateModal>) {
        return this.apiService.getOrganizationData()
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: OrganisationResponse) => {
                this.utils.showSnackBar(res.data.exportCsv.message);
            })
        );
    }
    @Action(GetListHistory)
    getListHistory({patchState}: StateContext<OrganizationStateModal>, {listId}) {
        return this.apiService.getListHistory(listId)
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: OrganisationResponse) => {
                patchState({listHistory: res.data.getListHistory});
            })
        );
    }
    @Action(GetTimeZones)
    getTimeZones({ patchState}: StateContext<OrganizationStateModal>) {
        return  this.apiService.getTimeZones()
        .pipe(catchError(error => this.handleError(error, patchState)))
        .pipe(
            tap((res: OrganisationResponse) => {
                patchState({timeZones: res.data.getTimezones});
            })
        );
    }
    @Action(CreateDynamicTask) 
    createDynamicTasks({ patchState, dispatch }: StateContext<OrganizationStateModal>, { task }) {
        patchState({success: false , error: false});
        return  this.apiService.createDynamicTask(task)
            .pipe(catchError(error => this.handleError(error, patchState)))
            .pipe(
                tap((res: OrganisationResponse) => {
                    this.utils.showSnackBar(res.data.createDynamicTask.message);
                    patchState({success: true, message: res.data.createDynamicTask.message});
                    const filter: TaskFilterRequest = {
                        pagination: { page: 1, items: 10 },
                        orderBy: SortOrder.DESC,
                        sort: 'title',
                        search: {
                            title: ''
                        }
                    };
                    dispatch(new GetDynamicTasks(filter));
                })
            );
    }
    @Action(GetDynamicTasks)
    getDynamicTasksList({ patchState }: StateContext<OrganizationStateModal>, { request }) {
        patchState({ loading: true})
        return  this.apiService.getDynamicTasks(request)
            .pipe(catchError(error => this.handleError(error, patchState)))
            .pipe(
                tap((res: OrganisationResponse) => {
                    patchState({dynamicTaskList: res.data.getDynamicTaskList.dynamicTasks, pagination: res.data.getDynamicTaskList.pagination , loading: false });
                })
            );
    }
    @Action(UpdateDynamicTask)
    updateDynamicTasksList({ patchState, dispatch }: StateContext<OrganizationStateModal>, { request }) {
        patchState({ loading: true});
        return  this.apiService.updateDynamicTasks(request)
            .pipe(catchError(error => this.handleError(error, patchState)))
            .pipe(
                tap((res: OrganisationResponse) => {
                    dispatch(new GetDynamicTasks())
                    this.utils.showSnackBar(res.data.updateDynamicTaskStatus.message);
                    patchState({ loading: false , message: res.data.updateDynamicTaskStatus.message });
                })
            );
    }
    @Action(DeleteDynamicTask)
    deleteDynamicTasksList({ patchState , dispatch}: StateContext<OrganizationStateModal>, { taskId }) {
        patchState({ loading: true});
        return  this.apiService.deleteDynamicTasks(taskId)
            .pipe(catchError(error => this.handleError(error, patchState)))
            .pipe(
                tap((res: OrganisationResponse) => {
                    dispatch(new GetDynamicTasks())
                    this.utils.showSnackBar(res.data.deleteDynamicTask.message);
                    patchState({ loading: false , message: res.data.deleteDynamicTask.message });
                })
            );
    }
    @Action(GetGroupSize)
    getGroupSize({ patchState , dispatch}: StateContext<OrganizationStateModal>, { dynamicTaskId }) {
        patchState({ loading: true});
        return  this.apiService.getGroupSize(dynamicTaskId)
            .pipe(catchError(error => this.handleError(error, patchState)))
            .pipe(
                tap((res: OrganisationResponse) => {
                    patchState({ loading: false , error : false, groupSize: res.data.getUserGroupSize });
                })
            );
    }
}
