import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError, fromEvent , merge ,of } from 'rxjs';
import { catchError, map  , mapTo   } from 'rxjs/operators';

import { UserService } from 'app/store/users-store/user.service';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { Injectable } from '@angular/core';
import { UtilService } from '../services/utils.service';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  token;
  refToken;
  constructor(
    private userService: UserService,
    private utils: UtilService,
    private authService: JwtAuthService,
    ) {
    this.token = this.authService.getJwtToken();
    this.refToken = this.authService.getRefToken();

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {

            const errorMsg = '';
          if (error.error?.errors[0]?.extensions?.code == 'UNAUTHENTICATED') {
            console.log(`Error: ${error.message}`);
            localStorage.clear();
            window.location.href = '/sessions/signin';
            return;
          }
          return throwError(errorMsg);
        })
      )
      .pipe(
        map((event: HttpEvent<any>) => {

            if (event instanceof HttpResponse) {
              const errors = event.body.errors;
              if ( errors && errors.length > 0 ) {
                event.body.errors.forEach((element, index) => {

                  event.body.errors[index].message = this.utils.simplifyString(element.message);
                });
              }
              if ( errors && (errors[0].extensions.code === 'UNAUTHENTICATED' || errors[0].extensions.code === 'FORBIDDEN') ) {
                console.log(`Error: ${errors[0].message}`);
                localStorage.clear();
                window.location.href = '/sessions/signin';
                return;
              }
              return event;
          }
        })
      );
  }
}
