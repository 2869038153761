<div class="container-dynamic">
  <div
    class="breadcrumb-bar"
    *ngIf="
      layout.layoutConf.useBreadcrumb &&
      layout.layoutConf.breadcrumb === 'simple'
    "
  >
<!--    <ul class="breadcrumb">-->
<!--      <li class="breadcrumb-item">-->
<!--        {{ routeParts[0].breadcrumb | translate }}-->
<!--      </li>-->
<!--    </ul>-->
    <ul class="breadcrumb">
      <li >
        <span *ngIf="!state.title && routeParts[1]?.breadcrumb !== 'Workflow' &&  routeParts[0]"> {{ routeParts[0]?.breadcrumb | translate }} </span>
        <div *ngIf="state && state.title " fxLayout="row" fxLayoutAlign="space-around center" >
          <mat-icon class="back-icon" (click)="back()">keyboard_arrow_left</mat-icon>
          <span class="pl-12 ">
            {{ routeParts[1]?.breadcrumb | translate }} : {{ state.title}}
          </span>
        </div>
      </li>
    </ul>
  </div>

  <div
    class="breadcrumb-title"
    *ngIf="
      layout.layoutConf.useBreadcrumb &&
      layout.layoutConf.breadcrumb === 'title'
    "
  >
    <h1 class="bc-title">
      {{ routeParts[routeParts.length - 1]?.breadcrumb | translate }}
    </h1>
    <ul class="breadcrumb" *ngIf="routeParts.length > 1">
      <li *ngFor="let part of routeParts">
        <a routerLink="/{{ part.url }}" class="text-muted">{{
          part.breadcrumb | translate
        }}</a>
      </li>
    </ul>
  </div>
</div>
