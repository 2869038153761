import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { CurrentRoles } from "app/store/users-store/user.model";
import { UserSelectors } from "app/store/users-store/user.selectors";
import { Observable , pipe } from "rxjs";
import { skipWhile } from "rxjs-compat/operator/skipWhile";
import { take } from "rxjs/operators";

@Pipe({ name: 'role' })
export class RolePipe implements PipeTransform {
  isAdmin
  @Select(UserSelectors.getRoles) isSuperAdmin$ : Observable<CurrentRoles>

     constructor( ){
       this.isSuperAdmin$.pipe(take(1)).subscribe( res => {
         this.isAdmin = res.superAdmin
       })
     }

   async transform(text: string) {
    if(this.isAdmin) return text
    return text.replace(',organization', '')
  }



}