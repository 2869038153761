import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Select } from '@ngxs/store';
import { GeneralStoreSelector } from 'app/store/GeneralStore/general-store.selectors';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { JwtAuthService } from '../services/auth/jwt-auth.service';


@Injectable()
export class OTPGuard implements CanActivate {
  @Select(GeneralStoreSelector.getOTPStatus) otpVerified$: Observable<boolean>;

  constructor(private router: Router, private jwtAuth: JwtAuthService, private snack: MatSnackBar) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (await this.isOTPVerified().toPromise()) {
            return true;
        } 
        else {
            this.jwtAuth.signout();
            this.router.navigate(['/sessions/signin'], {
                queryParams: {
                  return: state.url
                }
            });
            return false;
        }
    }

    isOTPVerified(): Observable<boolean> {
        return this.otpVerified$.pipe(take(1))
    }
}