
import { Injectable } from "@angular/core";
import { Selector } from '@ngxs/store'
import {  PersonActivityStateModel } from "./person-activity.model";
import { PersonActivityState  } from './person-activity.state'


@Injectable()
export class PersonActivitySelectors {
    @Selector([PersonActivityState])
    static personActivity(state) {
        return state;
    }
    @Selector([PersonActivityState])
    static personVaccDetail(state) {
        return state.userVaccDetail;
    }
    @Selector([PersonActivityState])
    static personVaccCards(state) {
        return state.userVaccCards;
    }

    @Selector([PersonActivityState])
    static getPersonActivities(state: PersonActivityStateModel) {
        return state.activities
    }
    @Selector([PersonActivityState])
    static activitiesLoading(state) {
        return state.activitiesLoading;
    }

    @Selector([PersonActivityState])
    static vaccinationCards(state) {
        return state.userVaccCards.filter(e => e.type === "VACCINATION_CARD")
    }
  
    @Selector([PersonActivityState])
    static VCErrors(state: PersonActivityStateModel ) {
        return state.err
    }
    @Selector([PersonActivityState])
    static GetActivities(state: PersonActivityStateModel ) {
        let arr = []
        state.activities.forEach(ele => {
            if(ele.activityName === 'VACCINATION_1' || ele.activityName === 'VACCINATION_2' || ele.activityName === 'VACCINATION_BOOSTER_1'){
                arr.push(ele)
            }
        })
        return arr.length > 0
    }
    @Selector([PersonActivityState])
    static personActivityLogs(state: PersonActivityStateModel){
        return state.activityLogs
    }
   
    @Selector([PersonActivityState])
    static recentContacts(state: PersonActivityStateModel ){
        return state.recentContact
    }
   
    @Selector([PersonActivityState])
    static userId(state: PersonActivityStateModel){
        return state.userId
    }
   
  
}
