import { PersonActivityListRequestObject, PersonActivityCreateRequestObject, PersonActivityUpdateRequestObject, PersonVaccCardsReqObj } from "./person-activity.model";
import  * as ModelData  from './person-activity.model'
import { User } from "../users-store/user.model";



export class SetuserId {
    static readonly type = '[User Id] set UserId'
    constructor(public userId: number) {}
}
export class GetGeneralData {
    static readonly type = '[GeneralData] GetPersonData'
    constructor(public loadAllUsers: boolean = true) {}
}

export class GetPersonActivity {
    static readonly type = '[PersonActivity] GetPersonActivity'
    constructor(public getPersonActivty: PersonActivityListRequestObject) {}
}

export class AddPersonActivity {
    static readonly type = '[PersonActivity] AddPersonActivity'
    constructor(public AddPersonActivityObj: PersonActivityCreateRequestObject) {}
}
export class GetPersonVaccDetail {
    static readonly type = '[PersonActivity] GetPersonVaccDetail'
   
}
export class UpdatePersonActivity {
    static readonly type = '[PersonActivity] UpdatePersonActivity'
    constructor(public UpdatePersonActivityObj: PersonActivityUpdateRequestObject) {}
}
export class DeletePersonActivity {
    static readonly type = '[PersonActivity] Delete Person Activity'
    constructor(public activityId: number) {}
}

export class ClearPersonActivitiesState {
    static readonly type = '[PersonActivity] ClearPersonActivityState'
}
export class GetUserVaccCards {
    static readonly type = '[PersonVaccineCards] GetPVCD'
    constructor(public GetPVCD: PersonVaccCardsReqObj) {}
}
export class AddMultipleMedicalRecords {
    static readonly type = '[PersonVaccineCards] AddMultiCards'
    constructor(public records: PersonVaccCardsReqObj[]) {}
}
export class GetPersonActivityLog {
    static readonly type = '[PersonActivityLogs] Get Person Activity Logs'
    constructor(public request: ModelData.PersonActivityLogsObj) {}
}
export class ContactTracing {
    static readonly type = '[Contact Tracing] Add Contact Tracing'
    constructor(public contact?: User, public action?: ModelData.contactTracingAction) {}
}
export class CreateContactTracingActivity{
    static readonly type = '[Contact Tracing] Create Contact Tracing Activity';
   
}
export class GetUserRecentContacts{
    static readonly type = '[Contact Tracing] Get Single users recent contacts';
}
export class UpdateContacts{
    static readonly type = '[Contact Tracing] Update Contact Tracings';
}
export class SetUpdate{
    static readonly type = '[Contact Tracing] Set contacts to update';
}
export class SypmtomScreening{
    static readonly type = '[Symptom Screening] Symptom Screening Activity';
    constructor( public request: ModelData.ScreeningRequest) {}
}
export class ClearContacts{
    static readonly type = '[Symptom Screening] Clear Contacts';
}
export class AddContactsToEdit {
    static readonly  type = '[Add contacts to Edit] Edit Contacts';
    constructor(public contacts: any, public medicalId: number ) {}
}
