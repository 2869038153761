import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Permissions } from '../util/permissions';
import { RolesId} from '../util/types';
import { JwtAuthService } from './auth/jwt-auth.service';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  permission?: Permissions;
  role?: RolesId;
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;  // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  allMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'link',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_pie_chart',
      state: 'dashboard/overview',
      permission: Permissions.upload_new_lists
    },
    {
      name: 'Organizations',
      type: 'link',
      tooltip: 'Organizations',
      icon: 'grid_on',
      state: 'organizations',
      role: RolesId.SUPER_ADMIN
    },
    {
      name: 'Lists & People',
      type: 'link',
      tooltip: '',
      icon: 'people',
      state: 'list-people/tabs',
      permission: Permissions.upload_new_lists
    },
    {
      name: 'Tasks',
      type: 'link',
      tooltip: 'Task',
      icon: 'lightbulb_outline',
      state: 'tasks/list',
      permission: Permissions.workflow_creation
    },
    {
      name: 'Admins',
      type: 'link',
      tooltip: 'admin',
      icon: 'person',
      state: 'admin/admin-user',
      role: RolesId.SUPER_ADMIN
    },
    {
      name: 'Users',
      type: 'link',
      tooltip: 'users',
      icon: 'person',
      state: 'users',
      permission: Permissions.user_creation
    },
    {
      name: 'Organization Settings',
      type: 'link',
      tooltip: 'Setting',
      icon: 'grid_on',
      state: 'organization-setting',
      role: RolesId.ORG_ADMIN
    }
  ];
  adminMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'link',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_pie_chart',
      state: 'dashboard/overview'
    },
    {
      name: 'Organizations',
      type: 'link',
      tooltip: 'Organizations',
      icon: 'grid_on',
      state: 'organizations'

    },
    {
      name: 'Admins',
      type: 'link',
      tooltip: 'admin',
      icon: 'person',
      state: 'admin/admin-user'
    },
    // {
    //   name: 'Users',
    //   type: 'link',
    //   tooltip: 'users',
    //   icon: 'person',
    //   state: 'users'
    // },
    {
      type: 'separator',
      name: ''
    }
  ];

  orgAdminMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'link',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_pie_chart',
      state: 'dashboard/overview'
    },
    {
      name: 'Lists & People',
      type: 'link',
      tooltip: '',
      icon: 'people',
      state: 'list-people/tabs'
    },
    {
      name: 'Tasks',
      type: 'link',
      tooltip: 'Task',
      icon: 'lightbulb_outline',
      state: 'tasks/list'

    },
    {
      name: 'Users',
      type: 'link',
      tooltip: 'users',
      icon: 'person',
      state: 'users'
    },
    {
      name: 'Organization Settings',
      type: 'link',
      tooltip: 'Setting',
      icon: 'grid_on',
      state: 'organization-setting'
    }
  ];

  SManagerMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'link',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_pie_chart',
      state: 'dashboard/overview'
    },
    {
      name: 'Lists & People',
      type: 'link',
      tooltip: '',
      icon: 'people',
      state: 'list-people/tabs'
    },
    {
      name: 'Tasks',
      type: 'link',
      tooltip: 'Task',
      icon: 'lightbulb_outline',
      state: 'tasks/list'

    },
    {
      name: 'Users',
      type: 'link',
      tooltip: 'users',
      icon: 'person',
      state: 'users'
    },

    {
      type: 'separator',
      name: ''
    }
  ];

  jrAdminMenu: IMenuItem[] = [
    {
      name: 'Tasks',
      type: 'link',
      tooltip: 'Task',
      icon: 'lightbulb_outline',
      state: 'tasks/list'
    }
  ];

  iconTypeMenuTitle = 'Frequently Accessed';
  menuItems = new BehaviorSubject<IMenuItem[]>(this.adminMenu);
  menuItems$ = this.menuItems.asObservable();
  constructor(private router: Router, private authService: JwtAuthService) {}

  publishNavigationChange(userRole: number, permissions: string[]) {
    switch (userRole) {
      case RolesId.SUPER_ADMIN:
        this.menuItems.next(this.adminMenu);
        break;
      case RolesId.ORG_ADMIN:
        this.menuItems.next(this.orgAdminMenu);
        break;
      default:
        const itemsToShow = this.allMenu.filter((menuItem: IMenuItem) => {
          if ( menuItem.role && (menuItem.role === userRole)) {
            return menuItem;
          }

          if ( menuItem.permission && (permissions.includes(menuItem.permission)) ) {
            return menuItem;
          }
        });

        this.menuItems.next(itemsToShow);
    }


  }

  redirectUser(_permissions: string[], userRole: number = -1) {
    let isLoggedIn = false;
    if ( userRole === RolesId.SUPER_ADMIN || userRole === RolesId.ORG_ADMIN ) {
      this.router.navigateByUrl('/dashboard/overview');
      return;
    }
    for (const permission of _permissions) {
      if ( permission === Permissions.upload_new_lists ) {
        isLoggedIn = true;
        this.router.navigateByUrl('/dashboard/overview');
        break;
      }

      if ( permission === Permissions.workflow_creation ) {
        isLoggedIn = true;
        this.router.navigateByUrl('/tasks/list');
        break;
      }

      if ( permission === Permissions.user_creation ) {
        isLoggedIn = true;
        this.router.navigateByUrl('/users');
        break;
      }
    }

    if (userRole > -1) {
      this.router.navigateByUrl('/dashboard/overview');
      return;
    }

    if (!isLoggedIn) {
      this.authService.signout();
      this.router.navigateByUrl('/sessions/error');
    }
  }
}
