
import { Pagination, FileEntity, Diagnosis, VaccinationCardAddInput, TestResultFileAddInput, SortOrder,  DocumentType, VaccinationRecords, ActivityLog, ActivityName, VaccineManufacturer, Vaccine, WhichTestDidYouReceive } from "app/shared/util/types"
import { User } from "../users-store/user.model"

export interface PersonActivityStateModel {
    userId?: number;
    loading?: boolean;
    activities?: Array<PersonActivityListObject>;
    err?: any,
    message?: string,
    userVaccDetail?: PersonVaccDetails[];
    userVaccCards?: Array<PersonVaccCards>,
    cardLoading?: boolean,
    activitiesLoading?: boolean;
    activityLogs?: ActivityLog;
    contactTracing?: User[]
    recentContact?: RecentContact,
    MedicalId? : number;
}
export interface PersonVaccCardStateModel {
    userVaccCards?: Array<PersonVaccCards>,
    cardLoading?: boolean
}
export interface RecentContact {
    User: User[];
}
export interface PersonVaccCards {
    type: string
    medicalActivityId?: number
    documentType: string
    filePath: string
    fileName: string
    fileSizeBytes: string
    fileExtension: string
}
export interface PersonActivityListObject {
    id: number,
    activityName: ActivityName,
    result?: string,
    screeningTermsConditions?: boolean
    firstName?: string
    lastName?: string
    dateOfBirth?: string
    email?: string
    testEveryXDays?: boolean
    planToDoNeither?: boolean
    whichTestDidYouReceive?: WhichTestDidYouReceive
    testAdministered?: string
    testAdministeredDate?: string
    diagnosis?: Diagnosis
    dontHaveTestResult?: boolean
    vaccinated?: boolean
    dontPlanToGetTested?: boolean
    TestResultFiles?: Array<TestResultFileAddInput>
    VaccinationCards?: Array<VaccinationCardAddInput>
    personalCode?: string
    vaccineManufacturer?: VaccineManufacturer
    vaccine?: Vaccine
    vaccineLotNumber?: string
    vaccinationDate?: string
    healthCareProviderOfficeOrSite?: string
    status?: string,
    createdAt?: string,
    ContactTracings?: RecentContact[]
    isHidden?: boolean
    activityDate?: string
    displayTitle?: string
    subTitle?: string
    color?: string
}
export interface PersonVaccDetails {
    vaccines?: {
        FIRST_DOSE_COVID_19?: boolean,
        SECOND_DOSE_COVID_19?: boolean,
        FIRST_BOOSTER_SHOT?: boolean
    },
    currentActivity?: string,
    getCovidTestEveryXDays?: boolean,
    planTodoNeither?: boolean,
    vaccinationStatus?: string
    currentStatus?: Object,
}

export interface GeneralDataReqObj {
    userId?: number,
}
export interface PersonActivityListRequestObject {
    userId?: number,
    sort?: string,
    orderBy?: SortOrder,
    pagination?: Pagination
}

export interface PersonActivityListResponseObject {
    data?: {
        getMedicalActivities?: Array<PersonActivityListObject>
    }
}



export interface VaccinationRecordAddInput {
    vaccineManufacturer: VaccineManufacturer
    vaccine: Vaccine
    vaccineLotNumber: string
    vaccinationDate: string
    healthCareProviderOfficeOrSite: string
}

export interface PersonActivityCreateRequestObject {
    userId: number,
    activityName?: ActivityName,
    screeningTermsConditions?: boolean
    firstName?: string
    lastName?: string
    dateOfBirth?: string
    email?: string
    testEveryXDays?: boolean
    planToDoNeither?: boolean
    whichTestDidYouReceive?: WhichTestDidYouReceive
    testAdministered?: string
    testAdministeredDate?: string
    diagnosis?: Diagnosis
    dontHaveTestResult?: boolean
    vaccinated?: boolean
    dontPlanToGetTested?: boolean
    TestResultFiles?: Array<TestResultFileAddInput>
    VaccinationCards?: Array<VaccinationCardAddInput>
    personalCode?: string
    vaccineManufacturer?: VaccineManufacturer
    vaccine?: Vaccine | string
    vaccineLotNumber?: string
    vaccinationDate?: string
    healthCareProviderOfficeOrSite?: string;
    activityDate?: string;
    receivedProvider?: boolean; 
    metCdcIsolation?: boolean; 
    receivedNegativeTest?: boolean; 
    lastThreeDaysSymptoms?: boolean; 
    confirmedOrProbableCovidCase?: boolean; 
    lastFourteenDaysDiagnosis?: boolean
}

export interface PersonActivityResponseObject {
    data?: {
        addMedicalActivity?: {
            message: string
        },
        updateMedicalActivity?: {
            message: string
        },
        deleteMedicalActivity? : {
            message: string
        }, 
        getUserActivityLogs?: ActivityLog,
        addContactTracings?: {
            message: string
        },
        updateContactTracings?: {
            message: string
        };
        getRecentContactTracing?: RecentContact
        
    }
}

export class PersonVaccDetailsResObj {
    data?: {
        getUserVaccinationMenuDetails
    }
}
export interface PersonActivityUpdateRequestObject {
    userId?: number,
    id: number,
    activityName: ActivityName,
    screeningTermsConditions?: boolean
    firstName?: string
    lastName?: string
    dateOfBirth?: string
    email?: string
    testEveryXDays?: boolean
    planToDoNeither?: boolean
    whichTestDidYouReceive?: WhichTestDidYouReceive
    testAdministered?: string
    testAdministeredDate?: string
    diagnosis?: Diagnosis
    dontHaveTestResult?: boolean
    vaccinated?: boolean
    dontPlanToGetTested?: boolean
    TestResultFiles?: Array<TestResultFileUpdateInput>
    VaccinationCards?: Array<VaccinationCardUpdateInput>
    vaccineManufacturer?: VaccineManufacturer
    vaccine?: Vaccine
    vaccineLotNumber?: string
    vaccinationDate?: string
    healthCareProviderOfficeOrSite?: string
}

export interface VaccinationCardUpdateInput {
    medicalActivityId: number
    documentType: DocumentType
    filePath: string
    fileName: string
    fileSizeBytes: string
    fileExtension: string
}

export interface TestResultFileUpdateInput  {
    medicalActivityId: number
    filePath: string
    fileName: string
    fileSizeBytes: string
    fileExtension: string
} 

export interface PersonVaccCardRespObj {
    data?: {
        getUserVaccinationCards?,
        addMultipleMedicalActivities?:{
            message: string;
        }
    }
}
export interface PersonVaccCardsReqObj {
    userId?: number,
    fileEntities?: Array<FileEntity>
}

export interface MedicalObj{
    id?: number
    activityName?: ActivityName
    screeningTermsConditions?: boolean
    firstName?: string
    lastName?: string
    dateOfBirth?: string
    email?: string
    testEveryXDays?: boolean
    planToDoNeither?: boolean
    whichTestDidYouReceive?: WhichTestDidYouReceive
    testAdministered?: string
    testAdministeredDate?: string
    diagnosis?: Diagnosis
    dontHaveTestResult?: boolean
    vaccinated?: boolean
    dontPlanToGetTested?: boolean
    VaccinationCards?: VaccinationCardAddInput[]
    TestResultFiles?: TestResultFileAddInput[]
    vaccineManufacturer?: VaccineManufacturer
    vaccine?: Vaccine
    vaccineLotNumber?: string
    vaccinationDate?: string
    VaccinationRecords?: VaccinationRecords[]
    healthCareProviderOfficeOrSite?: string
    documentType?: DocumentType
    status?: string
    result?: string
}
export enum contactTracingAction {
    ADD = "ADD",
    REMOVE = "REMOVE",
    UPDATE = "UPDATE"
}
export interface RecentContact {
    id: number;
    activityName: ActivityName;
    ContactTracings: RecentContact[]

}

export interface ScreeningRequest {
    userId: number;
    activityName:ActivityName;
    receivedNegativeTest?: boolean;
    metCdcIsolation?: boolean;
    receivedProvider?: boolean;
    lastFourteenDaysDiagnosis?: boolean;
    confirmedOrProbableCovidCase?: boolean;
    lastThreeDaysSymptoms?: boolean;
}

export interface PersonActivityLogsObj{
    userId: number;
    pagination: {
        page: number;
        items: number;
    },
    search: {
        activityName: string;
    },
    sort: string;
    orderBy: string;
}
