
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { AddMultipleMedicalActivitiesRequest } from './person-activity.requests';
import { PersonVaccCardRespObj, PersonActivityCreateRequestObject } from './person-activity.model';
import * as PersonActivityRequests from './person-activity.requests';
import * as PersonActivityModels from './person-activity.model';


import { Observable } from 'rxjs';
import { User } from '../users-store/user.model';

@Injectable()
export class PersonActivityService {
    constructor(
      private apollo: Apollo,
      ) {}

    getPersonActivity(requestObj: PersonActivityModels.PersonActivityListRequestObject): Observable<PersonActivityModels.PersonActivityListResponseObject> {
      return this.apollo.query({
          query: PersonActivityRequests.getPersonActivity,
          variables: requestObj,
          fetchPolicy: 'network-only'
        });
    }

    addPersonActivity(requestObj: any): Observable<PersonActivityModels.PersonActivityResponseObject> {
      return this.apollo.mutate({
          mutation: PersonActivityRequests.addPersonActivity,
          variables: {
            userId: requestObj.userId,
            activityName: requestObj.activityName,
            screeningTermsConditions:  requestObj.screeningTermsConditions,
            firstName:  requestObj.firstName,
            lastName:  requestObj.lastName,
            dateOfBirth:  requestObj.dateOfBirth,
            email:  requestObj.email,
            testEveryXDays:  requestObj.testEveryXDays,
            planToDoNeither:  requestObj.planToDoNeither,
            whichTestDidYouReceive:  requestObj.whichTestDidYouReceive,
            testAdministered:  requestObj.testAdministered,
            testAdministeredDate:  requestObj.testAdministeredDate,
            diagnosis:  requestObj.diagnosis,
            dontHaveTestResult:  requestObj.dontHaveTestResult,
            vaccinated:  requestObj.vaccinated,
            dontPlanToGetTested:  requestObj.dontPlanToGetTested,
            TestResultFiles: requestObj.TestResultFiles,
            VaccinationCards:  requestObj.VaccinationCards,
            vaccineManufacturer : requestObj.vaccineManufacturer,
            vaccine : requestObj.vaccine,
            vaccineLotNumber : requestObj.vaccineLotNumber,
            vaccinationDate : requestObj.vaccinationDate,
            healthCareProviderOfficeOrSite : requestObj.healthCareProviderOfficeOrSite
          },
          fetchPolicy: 'network-only'
        });
    }

    getPersonVaccDetail(userId: number): Observable<PersonActivityModels.PersonVaccDetailsResObj> {
      return this.apollo.query({
        query: PersonActivityRequests.GETUVMD,
        variables: {
          userId
        },
        fetchPolicy: 'network-only'
      });
    }
    updatePersonActivity(request: any): Observable<PersonActivityModels.PersonActivityResponseObject> {

      return this.apollo.mutate({
          mutation: PersonActivityRequests.updatePersonActivity,
          variables: {
            userId: request.userId,
            id: request.id,
            activityName: request.activityName,
            screeningTermsConditions:  request.screeningTermsConditions,
            firstName:  request.firstName,
            lastName:  request.lastName,
            dateOfBirth:  request.dateOfBirth,
            email:  request.email,
            testEveryXDays:  request.testEveryXDays,
            planToDoNeither:  request.planToDoNeither,
            whichTestDidYouReceive:  request.whichTestDidYouReceive,
            testAdministered:  request.testAdministered,
            testAdministeredDate:  request.testAdministeredDate,
            diagnosis:  request.diagnosis,
            dontHaveTestResult:  request.dontHaveTestResult,
            vaccinated:  request.vaccinated,
            dontPlanToGetTested:  request.dontPlanToGetTested,
            TestResultFiles: request.TestResultFiles,
            VaccinationCards:  request.VaccinationCards,
            vaccineManufacturer : request.vaccineManufacturer,
            vaccine : request.vaccine,
            vaccineLotNumber : request.vaccineLotNumber,
            vaccinationDate : request.vaccinationDate,
            healthCareProviderOfficeOrSite : request.healthCareProviderOfficeOrSite
          },
          fetchPolicy: 'network-only'
        });
    }
    deletePersonActivity(id: number): Observable<PersonActivityModels.PersonActivityResponseObject> {

      return this.apollo.mutate({
          mutation: PersonActivityRequests.DeletePersonActivity,
          variables: { id}
        });
    }

    getUserVaccCards(request: PersonActivityModels.PersonVaccCardsReqObj): Observable<PersonActivityModels.PersonVaccCardRespObj> {
      return this.apollo.query({
        query: PersonActivityRequests.GETUVC,
        variables: {
          userId: request.userId,
          fileEntities: request.fileEntities
        },
        fetchPolicy: 'network-only'
      });
    }
    addMultipleMedicalRecords(medicalRecord: PersonActivityCreateRequestObject[], userId): Observable<PersonVaccCardRespObj> {
      return this.apollo.mutate({
          mutation: AddMultipleMedicalActivitiesRequest,
          variables: {
            activities: medicalRecord,
              userId
          }
      });
  }
  getPersonActivityLogs(request: PersonActivityModels.PersonActivityLogsObj): Observable<PersonActivityModels.PersonActivityResponseObject> {
    return this.apollo.query({
      query: PersonActivityRequests.GET_PERSON_ACTIVITY,
      variables: request,
      fetchPolicy: 'network-only'
  });
  }
    addMultipleContactTracing(contacts: User[], id: number): Observable<PersonActivityModels.PersonActivityResponseObject> {
      return this.apollo.mutate({
          mutation: PersonActivityRequests.AddBulkContactTracing,
          variables: {
              contacts,
              userId: id
          }
      });
  }
  updateContactTracing(contacts: User[], id: number): Observable<PersonActivityModels.PersonActivityResponseObject> {
    return this.apollo.mutate({
        mutation: PersonActivityRequests.UpdateContactTracing,
        variables: {
            contacts,
            medicalActivityId: id
        }
    });
  }
  getUserRecentContacts(userId: number): Observable<PersonActivityModels.PersonActivityResponseObject> {
    return this.apollo.query({
      query: PersonActivityRequests.GetUserContacts,
      variables: {
        userId,
      },
      fetchPolicy: 'network-only'
    });
  }
  symptomScreening(request: PersonActivityModels.ScreeningRequest): Observable<PersonActivityModels.PersonActivityResponseObject> {
    return this.apollo.mutate({
      mutation: PersonActivityRequests.addScreening,
      variables: request
    });
  }

}
