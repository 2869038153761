import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { AfterLoginAction } from 'app/store/users-store/user.action';
import { User } from 'app/store/users-store/user.model';
import { UserSelectors } from 'app/store/users-store/user.selectors';
import { Observable } from 'rxjs';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { NavigationService } from '../services/navigation.service';
import {GetOrganization} from '../../store/OrganizationStore/organization.action';

@Injectable()
export class AuthGuard implements CanActivate {
  @Select(UserSelectors.getUserData) currUser$: Observable<User>;

  constructor(private router: Router, private jwtAuth: JwtAuthService,
    private navService: NavigationService, public store: Store) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.jwtAuth.isLoggedIn()) {
      this.store.dispatch(new AfterLoginAction());
      const role = await this.jwtAuth.getRole();
      if(role !== 1) {
        this.store.dispatch(new GetOrganization())
      }
      const permissions = await this.jwtAuth.getPermissions();
      this.navService.publishNavigationChange(role, permissions);
      return true;
    } else {
      this.router.navigate(['/sessions/signin'], {
        queryParams: {
          return: state.url
        }
      });
      return false;
    }
  }
}
