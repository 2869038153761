import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { TaskWorkflowCreateObject, TaskWorkflowListRequestObject, TaskWorkflowStateModel, TaskDetailRequestObject } from './task-workflow.model';
import { CreateTaskWorkflow, GetTaskWorkflows, GetTaskDetail, UpdateTaskNotes, GetMyTaskWorkflows } from './task-workflow.action';
import { TaskWorkflowService } from './task-workflow.service';
import { UtilService } from 'app/shared/services/utils.service';

@State<TaskWorkflowStateModel>({
    name: 'TaskWorkflow',
    defaults: {
        loading: null,
        taskworkflows: [],
        taskDetail: null,
        err: null,
        message: null,
        pagination: {
            page: 1,
            totalPages: 1,
            totalRecords: 1
        }
    }
})

@Injectable()
export class TaskWorkflowState {

    constructor(private apiService: TaskWorkflowService , public utils: UtilService) {}

    private handleError(error: string, patchState: any = null, showSnackbar: boolean = true) {
        if(showSnackbar){
            this.utils.showSnackBar(error);
        }
        if (patchState) {
            patchState({loading: false, err: error});
        }
        return [];
    }

    @Action(CreateTaskWorkflow)
    createTaskWorkFlow({patchState}: StateContext<TaskWorkflowStateModel>, {taskWorkflowObject}) {
        patchState({
            loading: true
        });
        return this.apiService.createTask(taskWorkflowObject)
        .pipe(
            tap((res) => {
                this.utils.showSnackBar(res.data.createTask.message);
                patchState({loading: false, message: res.data.createTask.message});
            })
        ).pipe(catchError(error => this.handleError(error, patchState)));
    }

    @Action(GetTaskWorkflows)
    getTaskWorkFlows({patchState}: StateContext<TaskWorkflowStateModel>, taskworkflow: TaskWorkflowListRequestObject) {
        patchState({
            loading: true
        });
        return this.apiService.getTasks(taskworkflow)
        .pipe(
            tap((res) => patchState({loading: false, taskworkflows: res.data.getTaskList.tasks, pagination: res.data.getTaskList.pagination}))
        ).pipe(catchError(error => this.handleError(error, patchState)));
    }

    @Action(GetMyTaskWorkflows)
    getMyTaskWorkFlows({patchState}: StateContext<TaskWorkflowStateModel>, taskworkflow: TaskWorkflowListRequestObject) {
        patchState({
            loading: true
        });
        return this.apiService.getTasks(taskworkflow)
        .pipe(
            tap((res) => patchState({loading: false, myTaskWorkflows: res.data.getTaskList.tasks, myTasksPagination: res.data.getTaskList.pagination}))
        ).pipe(catchError(error => this.handleError(error, patchState)));
    }

    @Action(GetTaskDetail)
    getTaskDetail({patchState}: StateContext<TaskWorkflowStateModel>, { request }) {
        patchState({
            loading: true,
            taskDetail: null,
            err: null
        });
        return this.apiService.getTaskDetail(request)
        .pipe(
            tap((res) => patchState({loading: false, taskDetail: res.data.getTaskDetail, pagination: res.data.getTaskDetail.pagination}))
        ).pipe(catchError(error => this.handleError(error, patchState, false)));
    }

    @Action(UpdateTaskNotes)
    updateTaskNotes({patchState}: StateContext<TaskWorkflowStateModel>, {taskNotesRequestObject}) {
        patchState({
            loading: true
        });
        return this.apiService.updateTaskNotes(taskNotesRequestObject)
        .pipe(
            tap((res) => {
                this.utils.showSnackBar(res.data.updateTaskNotes.message);
                patchState({loading: null, message: res.data.updateTaskNotes.message});
            })
        ).pipe(catchError(error => this.handleError(error, patchState)));
    }

}
