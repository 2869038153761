import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeAgoPipe } from "ngx-pipes";

import { RelativeTimePipe } from './relative-time.pipe';
import { ExcerptPipe } from "./excerpt.pipe";
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { NullPipe } from './null.pipe';
import { ConvertString } from './contentdata.pipe';
import { RolePipe } from './role.pipe';
import { FormatPhoneNumberPipe } from './format-phone.pipe';

const pipes = [
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  NullPipe,
  ConvertString,
  RolePipe,
  FormatPhoneNumberPipe
]

@NgModule({
  providers: [TimeAgoPipe],
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes
})

export class SharedPipesModule {}
