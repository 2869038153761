import { gql } from 'apollo-angular';

export const getDashboardStats = gql`
    query dashboardStats($refinements: DashboardRefinements){
            dashboardStats(refinements: $refinements) {
                vaccinationCompliance {
                    employees,
                    noResponse,
                    engagement,
                    vaccinated,
                    notVaccinated,
                    firstVaccine,
                    secondVaccine,
                    fullyVaccinated,
                    partiallyVaccinated,
                    fullyVaccinatedWithBooster
                }
                testingSymptomScreening {
                    sent,
                    nDay,
                    noResponse,
                    tested,
                    covidPositive,
                    covidNegative,
                    inQuarantine,
                    covidFacility,
                    engagement
                }
                contactTracing {
                    sent,
                    responded,
                    covidPositive
                }
                careManagementActivity {
                    totalTasks,
                    calls,
                    updates,
                    openTasks
                }
            }
        }`;

export const getDashboardChartData = gql`
  query GetDashboardChartData($refinements: DashboardRefinements) {
    getDashboardChartData(refinements: $refinements) {
      seriesOne,
      seriesTwo
    }
  }
`;
