<div class="sidebar-panel">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">
            
            <div class="branding">
                <ng-container *ngIf="(appInfo$ | async) as appInfo; else noLogo">
                    <img [src]="appInfo.logo" alt="" class="app-logo">
                </ng-container>
                <ng-template #noLogo>
                    <img src="assets/images/logos/logo-1.png" alt="" class="app-logo">
                </ng-template>
            </div>
        
            <div class="app-user">
            </div>
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
        </div>
        <div class="sidebar-hold bottom">
            <div class="sidenav-hold bottom">
                <ul class="sidenav sidenav-bottom">
                    <li class="ng-star-inserted">
                        <div class="lvl1 ng-star-inserted">
                            <a class="mat-ripple ng-star-inserted" (click)="jwtAuth.signout()" style="flex-direction: row; box-sizing: border-box; display: flex;">
                                <mat-icon role="img" class="mat-icon notranslate sidenav-mat-icon material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true" data-mat-icon-type="font">exit_to_app</mat-icon>
                                <span class="item-name lvl1">Log Out</span>
                                <span fxflex="" ng-reflect-fx-flex="" style="flex: 1 1 0%; box-sizing: border-box;"></span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>            
        </div>
        <span class="app-version">App Version: {{appVersion}}</span>
    </div>
</div>
