import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { catchError, tap } from 'rxjs/operators';
import {PreSignedModel, SingleUserObject, PeopleModel, GetUsersResponseObject} from './people.model';
import {
    PreSignedUrl,
    ImportMembersAction,
    GetUsersAction,
    GetSingleUserAction,
    ClearUserStateAction,
    GetJuniorManagers, GetUserLogs, SetPeoplePaginationAction, SetPeopleRefinementsAction, ClearUserLogs, DeleteUserAction
} from './people.action';
import { PeopleService } from './people.service';
import { appsignal } from 'app/app.module';
import { UtilService } from 'app/shared/services/utils.service';
import { Roles, SortOrder } from 'app/shared/util/types';
import { GetLists } from '../list-store/list.action';
import { ListRequest } from '../list-store/list.model';
import { MatSnackBar } from '@angular/material/snack-bar';
@State<PeopleModel>({
    name: 'People',
    defaults: {
    message: '',
    peoplePagination: {
        items: 10,
        page: 1
    },
    peopleRefinements: {
        roles: [Roles.MEMBER]
    },
    loading: undefined,
    pagination : {
        totalRecords: 0,
        totalPages: 0,
        page: 0
    } ,
    userLogsPagination : {
        totalRecords: 0,
        totalPages: 0,
        page: 0
    } ,
    people: [],
    juniorAdmins: [],
        userLogs: []
},

})

@Injectable()
export class PeopleState {

    constructor(private apollo: Apollo, public utils: UtilService, private apiService: PeopleService, private snackBar: MatSnackBar) {}

    openSnackBar(mess) {
        this.snackBar.open(mess, 'Close', { duration: 5000});
    }

    private handleError(error: Error, patchState: any = null) {
        if (patchState) {
            patchState({loading: false});
        }
        appsignal.sendError(error , (span) => {
            span.setAction('People Error ');
            span.setNamespace('people');
            span.setTags({ tag1: 'people' });
        });
        return [];
    }

    @Action(PreSignedUrl)
    updateCurrUserSettings({ patchState, dispatch }: StateContext<PreSignedModel> , {preSignedObj}) {

        patchState({
         loading: true
        });
        return this.apiService.preSignedUrl(preSignedObj)
        .pipe(
            tap((res) => {
               patchState({  filePath: res.data.getPreSignedUrl.filePath,  url: res.data.getPreSignedUrl.url , loading: false });
            })
        ).pipe(catchError(error => this.handleError(error)));
    }

    @Action(ImportMembersAction)
    importMembers({ patchState, dispatch }: StateContext<PreSignedModel>, {membersObj}) {
        patchState({loading: true});
        return this.apiService.importMembers(membersObj)
        .pipe(
            tap((res) => {
                this.utils.showSnackBar(res.data.importMembers.message, 5000);
                    patchState({
                        message: res.data.importMembers.message,
                        loading: false
                    });
                    const obj: ListRequest = {
                        pagination : {
                          page: 1,
                          items: 100
                        },
                        sort: 'createdAt',
                        sortOrder: SortOrder.DESC
                      };
                    dispatch( new  GetLists(obj));
            })
        ).pipe(catchError(error => this.handleError(error)));
    }

    @Action(GetUsersAction)
    getUsers({patchState}: StateContext<PeopleModel>, {usersRequestObject}) {
        patchState({loadingPeople: true});
        return this.apiService.getUsers(usersRequestObject)
            .pipe(
                tap((res: any) => {
                    patchState({loadingPeople: false, people: res.data.getUserList.users, pagination: res.data.getUserList.pagination });
                })
            ).pipe(catchError(error => this.handleError(error)));
    }

    @Action(DeleteUserAction)
    deleteUser({patchState, dispatch}: StateContext<PeopleModel>, {userId, reqObj}) {
        return this.apiService.deleteUser(userId)
        .pipe(
            tap((res) => {
                this.openSnackBar(res.data.deleteAdminUser.message);
                dispatch(new GetUsersAction(reqObj))
            })
        ).pipe(catchError(error => this.handleError(error)));
    }

    @Action(GetJuniorManagers)
    getJManagers({patchState}: StateContext<PeopleModel>, {usersRequestObject}) {
        patchState({loading: true});
        return this.apiService.getUsers(usersRequestObject)
            .pipe(
                tap((res: any) => {
                    patchState({loading: false, juniorAdmins: res.data.getUserList.users });
                })
            ).pipe(catchError(error => this.handleError(error)));
    }

    @Action(GetSingleUserAction)
    getSingleUser({patchState}: StateContext<PeopleModel>, {usersRequestObject}) {
        patchState({loadingUser: true});
        return this.apiService.getUsers(usersRequestObject)
            .pipe(
                tap((res: any) => {
                    patchState({loadingUser: false, user: res.data.getUserList.users[0]});
                })
            ).pipe(catchError(error => this.handleError(error)));
    }

    @Action(ClearUserStateAction)
    clearPersonState({patchState}: StateContext<PeopleModel>) {
        const user: SingleUserObject = {
            id: 0,
            firstName: '',
            lastName: '',
            phone: '',
            activityDate: '',
            diagnosis: ''
        };
        patchState({loadingUser: null, user});
    }
    @Action(GetUserLogs)
    getUserLogs({ patchState }: StateContext<PeopleModel> , { request }) {
        return this.apiService.getUserLogs(request)
            .pipe(
                tap((res: GetUsersResponseObject) => {
                    patchState({ userLogs: res.data.getUserLogs.logs, userLogsPagination: res.data.getUserLogs.pagination});
                })
            ).pipe(catchError(error => this.handleError(error)));
    }

    @Action(ClearUserLogs)
    clearUserLogs({ patchState }: StateContext<PeopleModel> , { request }) {
        patchState({
            userLogs: [], 
            userLogsPagination: {
                totalRecords: 0,
                totalPages: 0,
                page: 0
            }
        });
    }

    @Action(SetPeoplePaginationAction)
    setPeoplePagination({patchState}: StateContext<PeopleModel>, {request}) {
        patchState({peoplePagination: request});
    }

    @Action(SetPeopleRefinementsAction)
    setPeopleRefinements({patchState}: StateContext<PeopleModel>, {request}) {
        patchState({peopleRefinements: request});
    }

}
