import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { RoutePartsService } from './shared/services/route-parts.service';

import { filter  } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs'
import { UILibIconService } from './shared/services/ui-lib-icon.service';

import { Select } from '@ngxs/store'
import { UserSelectors } from 'app/store/users-store/user.selectors'
import { UserStateModal } from 'app/store/users-store/user.model'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  appTitle = 'COVID RTW Application';
  pageTitle = '';
  contextData: Subscription; 
  @Select(UserSelectors.getUserData) currentUser$: Observable<UserStateModal>;

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    iconService: UILibIconService

  ) {
    iconService.init()
  }

   ngOnInit() {
    this.changePageTitle();
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      routeParts.reverse();
      this.pageTitle = routeParts.map((part) => part.title ).reduce((partA, partI) => {return `${partA} > ${partI}`});
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
  ngOnDestroy(): void {
    this.contextData.unsubscribe()
    
  }
}
