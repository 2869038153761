import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import {
    AdminInviteObj,
    AdminUserObj,
    CreateDynamicTaskObj,
    GetOrganizationsRequest,
    OrganisationObj,
    OrganisationResponse,
    OrganizationConfig, TaskFilterRequest, TaskUpdateObj
} from './organization.model';
import * as OrgRequest from './organization.requests';
import {CREATE_DYNAMIC_TASK, DELETE_DYNAMIC_TASK, GET_DYNAMIC_TASK, GET_GROUP_SIZE, UPDATE_DYNAMIC_TASK} from './organization.requests';

@Injectable()
export class OrganizationService {
    constructor(
      private apollo: Apollo,
      ) {}

    ngOnInit() {
        console.log('in init call');
    }

    addOrganization(request: OrganisationObj): Observable<OrganisationResponse> {
      return this.apollo.mutate({
        mutation: OrgRequest.AddOrganizationRequest,
        variables: request
      });
    }

    editOrganization(request: OrganisationObj): Observable<OrganisationResponse> {
      return this.apollo.mutate({
        mutation: OrgRequest.EditOrganizationRequest,
        variables: request
      });
    }

    deleteOrganization(request: number): Observable<OrganisationResponse> {
      return this.apollo.mutate({
        mutation: OrgRequest.DeleteOrganizationRequest,
        variables: {
          id: request
        }
      });
    }

    getOrganizations(reqObj?: GetOrganizationsRequest): Observable<OrganisationResponse> {
      return this.apollo.query({
        query: OrgRequest.GetOrganizations,
        variables: reqObj,
        fetchPolicy: 'network-only'
      });
    }
    getOrganization(): Observable<OrganisationResponse> {
      return this.apollo.query({
        query: OrgRequest.GetOrganization,
        fetchPolicy: 'network-only'
      });
    }
    addAdmin(request: AdminUserObj): Observable<OrganisationResponse> {
      return this.apollo.mutate({
        mutation: OrgRequest.createAdminUserRequest,
        variables: request
      });
    }

    getADmins(): Observable<OrganisationResponse> {
      return this.apollo.query({
        query: OrgRequest.GetAdmins,
        fetchPolicy: 'network-only'
      });
    }
    getSequences(): Observable<OrganisationResponse> {
      return this.apollo.query({
        query: OrgRequest.GETSEQ,
        fetchPolicy: 'network-only'
      });
    }
    adminAcceptDeclineInvite(inviteObj: AdminInviteObj ): Observable<OrganisationResponse> {
      return this.apollo.mutate({
        mutation: OrgRequest.ADMININVITE,
        variables: inviteObj
      });
    }

    updateOrganizationConfig(configObj: OrganizationConfig ): Observable<OrganisationResponse> {
      return this.apollo.mutate({
        mutation: OrgRequest.UPDATEORGANIZATIONCONFIG,
        variables: configObj
      });
    }

    getOrganizationOnboardingDetails(): Observable<OrganisationResponse> {
      return this.apollo.query({
        query: OrgRequest.GETORGANIZATIONONBOARDING,
        fetchPolicy: 'network-only'
      });
    }

    getOrganizationData(): Observable<OrganisationResponse> {
      return this.apollo.mutate({
        mutation: OrgRequest.ORGANIZATIONEXPORTCSV
      });
    }
    getListHistory(listId: number): Observable<OrganisationResponse> {
      return this.apollo.mutate({
        mutation: OrgRequest.GET_LIST_HISTORY,
        variables: {
          listId
        },
        fetchPolicy: 'network-only'
      });
    }
    getTimeZones(): Observable<OrganisationResponse> {
      return this.apollo.query({
        query: OrgRequest.GET_TIME_ZONES,
        fetchPolicy: 'network-only'
      });
    }
    createDynamicTask(request: CreateDynamicTaskObj): Observable<OrganisationResponse> {
        return this.apollo.mutate({
            mutation: CREATE_DYNAMIC_TASK,
            variables: request
        });
    }
    getDynamicTasks(request?: TaskFilterRequest ): Observable<OrganisationResponse> {
        return this.apollo.query({
            query: GET_DYNAMIC_TASK,
            variables: request,
            fetchPolicy: 'network-only'
        });
    }
    updateDynamicTasks(request: TaskUpdateObj ): Observable<OrganisationResponse> {
        return this.apollo.mutate({
            mutation: UPDATE_DYNAMIC_TASK,
            variables: request,
        });
    }
    deleteDynamicTasks(taskId: number ): Observable<OrganisationResponse> {
        return this.apollo.mutate({
            mutation: DELETE_DYNAMIC_TASK,
            variables: {
                deleteDynamicTaskId: taskId
            },
        });
    }
    getGroupSize(taskId: number ): Observable<OrganisationResponse> {
        return this.apollo.query({
            query: GET_GROUP_SIZE,
            variables: {
                dynamicTaskId: taskId
            },
            fetchPolicy: 'network-only'
        });
    }
}
