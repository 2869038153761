
import { Injectable } from "@angular/core";
import { Selector } from '@ngxs/store'
import { UserStateModal } from './user.model'
import { UserState} from './user.state'

@Injectable()
export class UserSelectors {
    @Selector([UserState])
    static currUser(state) {
        return state;
    }

    @Selector([UserState])
    static contextData(state) {
        return state.contextData;
    }
    @Selector([UserState])
    static getUserData(state) {
        return state.userProfile;
    }

    @Selector([UserState])
    static getUserOrganization(state) {
        return state.organizationId;
    }
    @Selector([UserState])
    static getRoles(state:UserStateModal) {
        return state.userRoles
    }
    @Selector([UserState])
    static getPrimaryAdmins(state:UserStateModal) {
        return state.primaryAdmins
    }
    @Selector([UserState])
    static getPrimaryAdmin(state:UserStateModal) {
        return state.primaryAdmins.filter(admin => admin.isPrimaryAdmin)[0]
    }
    @Selector([UserState])
    static getInvitationInfo(state:UserStateModal) {
        return state
    }

    @Selector([UserState])
    static getPermissions(state:UserStateModal) {
        return state.userRights
    }
    @Selector([UserState])
    static isSuperAdmin(state:UserStateModal) {
        return state.userRoles.superAdmin
    }
}