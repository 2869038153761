import {Component, Input, OnInit} from '@angular/core';
import { ToastService } from 'app/shared/services/toast.service';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss']
})

export class ToastComponent implements  OnInit {
    
    toast: any = {}
    constructor(
        private toastService: ToastService
    ) {
        this.toastService.currentToastConfig.subscribe(res=>{
            this.toast = res;
        })
    }

    ngOnInit() {
        setTimeout(()=>{
            this.dismiss();
        }, this.toast?.duration)
    }

    dismiss(){
        this.toast.visible = false;
    }
}
