import { Injectable } from "@angular/core";
import { Selector } from "@ngxs/store";
import { MediaStateModal } from "./media.model";
import { MediaState } from "./media.state";


@Injectable()
export class MediaStateSelectors {
    @Selector([MediaState])
    static uploadUrl(state: MediaStateModal) {
        return {loading: state.loading, presignedUrl: state.presignedUrl};
    }

    @Selector([MediaState])
    static getVCAttachments(state: MediaStateModal) {
        return state.Attachments;
    }

    @Selector([MediaState])
    static getMediaStateLoading(state: MediaStateModal) {
        return state.loading;
    }

    @Selector([MediaState])
    static userDashboardImages(state: MediaStateModal) {
        return state.DashboardImages;
    }

    @Selector([MediaState])
    static isFileUploaded(state: MediaStateModal) {
        return state;
    }

    @Selector([MediaState])
    static getOrgLogo(state: MediaStateModal) {
        return state.OrgLogo;
    }
}
