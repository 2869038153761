
  <div class="header-div">
    <h1>Activity History</h1>
    <mat-icon class='close-icon' (click)="close()" removeIcon > clear</mat-icon>
  </div>
  <div class="bottom-div">
    <button mat-flat-button class="btn" color="primary"> {{data.user.firstName}} {{data.user.lastName}} </button>
    <div class="search-box search-bar mat-elevation-z2">
      <button mat-icon-button>
        <mat-icon>search</mat-icon>
      </button>
      <input
        (keyup) = "search($event)"
        type="text"
        #searchInput
        placeholder="Search By Activity Name"
      />
    </div>
  </div>
  
  
  <div class="table">
    {{ngAfterViewChecked()}}
    <ngx-datatable *ngIf="(activities$ | async) as activity"
    class="material"
    [count]="activity?.pagination?.totalRecords || 0"
    [offset]="activity?.pagination?.page - 1 || 0"
    
    [rows]="activity.activities"
    [columnMode]="'force'"
    [headerHeight]="60"
    [footerHeight]="70"
    rowHeight="60"
    [limit]="size"
  >
    <ngx-datatable-column prop="activityName" name="Activity Name">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.activityName | toSentence}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="result" name="Result">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.result | toSentence }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="date" name="Date">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.activityDate | date: 'medium'}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
      >
      <mat-paginator [length]="activity?.pagination?.totalRecords"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="itemsPerPage"
                    showFirstLastButtons="true"
                    (page)="updateItemsPerPage($event)"
                    aria-label="Select page">
      </mat-paginator>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

