import { SingleUserObject } from "../people-store/people.model";
import { AddUserList, DeleteListObj, ListObj, ListRequest } from "./list.model";

export class EditList {
    static readonly type = '[List] Edit List'
    constructor(public listObj: ListObj) {}
}

export class GetLists {
    static readonly type = '[List] Get Lists'
    constructor(public listReq: ListRequest) {}
}

export class BulkSequenceUpdate {
    static readonly type = '[List] Bulk Sequence Update'
    constructor(public sequenceIds: number[]) {}
}
export class ShowHideMultiEdit {
    static readonly type = '[List] ShowHideEdiltListButton'
    constructor(public show: boolean,public selectedList:ListObj[]) {}
}
export class CreateTaskWorkFlow {
    static readonly type = '[People] WorkFlowPeopleList'
    constructor(public people:SingleUserObject[]) {}
}
export class AddUserToList {
    static readonly type = '[List] AddUserToList'
    constructor(public userObj:AddUserList) {}
}
export class DeleteList {
    static readonly type = '[List] DeleteList'
    constructor(public listObj:DeleteListObj) {}
}
