
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { createTask, getTasks, getTaskDetail, updateTaskNotes } from './task-workflow.requests';
import {
    TaskWorkflowResponseObject,
    TaskWorkflowListResponseObject,
    TaskDetailResponseObject,
    TaskNotesUpdateRequestObject,
    TaskNotesUpdateResponseObject,
    TaskWorkflowCreateObject, GetTaskDetailsReqObj
} from './task-workflow.model';

import { Observable } from 'rxjs';

@Injectable()
export class TaskWorkflowService {
    constructor(
      private apollo: Apollo,
      ) {}

    createTask(requestObj: TaskWorkflowCreateObject): Observable<TaskWorkflowResponseObject> {
        return this.apollo.mutate({
            mutation: createTask,
            variables: requestObj
          });
    }

    getTasks(requestObj: any): Observable<TaskWorkflowListResponseObject> {
      const request = requestObj.taskWorkflowListRequestObject;
      return this.apollo.query({
          query: getTasks,
          variables: {
            search: request.search,
            sort: request.sort,
            sortOrder: request.sortOrder,
            pagination: request.pagination,
            allTask: request.allTask
          },
          fetchPolicy: 'network-only'
        });
    }

    getTaskDetail(request: GetTaskDetailsReqObj ): Observable<TaskDetailResponseObject> {
      return this.apollo.query({
          query: getTaskDetail,
          variables: request,
          fetchPolicy: 'network-only'
        });
    }

    updateTaskNotes(request: TaskNotesUpdateRequestObject): Observable<TaskNotesUpdateResponseObject> {
      return this.apollo.mutate({
          mutation: updateTaskNotes,
          variables: request
        });
  }
}
