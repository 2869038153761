import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, Observable } from 'rxjs';
import { FileEntity } from 'app/shared/util/types';
import { ExportCSVRequest, MediaServiceResponse } from './media.model';
import { exportCsv, GetVaccinationCards, PresignedUrlRequest } from './media.requests';

@Injectable()
export class MediaService {

    constructor(private apollo: Apollo){}

    getUrl(fileName: string, fileEntity: string): Observable<MediaServiceResponse> {
        return this.apollo.query({
            query: PresignedUrlRequest,
            variables: {
                fileName: fileName,
                fileEntity: fileEntity,
            },
            fetchPolicy: 'network-only'
        });
    }

    uploadFile(signedUrl,file): Observable<any>{
        var myHeaders = new Headers();
        myHeaders.append("x-ms-blob-type", "BlockBlob");
        myHeaders.append("Content-Type", file.type);
        
        let requestOptions: RequestInit = {
            method: 'PUT',
            headers: myHeaders,
            body: file,
            redirect: 'follow'
        };
        
        return from(fetch(signedUrl, requestOptions));
    }

    getUserVCCards(): Observable<MediaServiceResponse> {
        return this.apollo.query({
            query: GetVaccinationCards,
            variables: {
                fileEntities: [FileEntity.VACCINATION_CARD]
            },
            fetchPolicy: 'network-only'
        });
    }

    getDashboardImages(): Observable<MediaServiceResponse> {
        return this.apollo.query({
            query: GetVaccinationCards,
            fetchPolicy: 'network-only'
        });
    }

    exportCSV(reqObj: ExportCSVRequest): Observable<MediaServiceResponse> {
        return this.apollo.mutate({
            mutation: exportCsv,
            variables: reqObj
        });
    }
}
