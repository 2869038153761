
export class GetSequencesCriteria {
    static readonly type = '[General Store] sequence criteria';
    constructor(public userId?: number) {}
}
// tslint:disable-next-line:max-classes-per-file
export class ContentData {
    static  readonly type = '[App Content Data] Get Content data';
}

export class SessionVerifiedByOTP {
    static readonly type = '[General Store] Mark Session Verified';
}
