
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PreSignedUrlRequest, ImportMembersRequest, getUsers, USER_LOGS, deleteUser } from './people.request';
import {
  PreSignedObj,
  PreSignedServiceResponse,
  MembersObj,
  ImportMembersServiceResponse,
  GetUsersResponseObject,
  GetUsersResquestObject,
  UserLogsReqObject
} from './people.model';
import { Observable } from 'rxjs';


@Injectable()
export class PeopleService {
  constructor(
    public apollo: Apollo,
  ) { }

  preSignedUrl(request: PreSignedObj): Observable<PreSignedServiceResponse> {
    return this.apollo.mutate({
      mutation: PreSignedUrlRequest,
      variables: {
        fileName: request.fileName,
        fileEntity: request.fileEntity
      }
    });
  }
  public importMembers(request: MembersObj): Observable<ImportMembersServiceResponse> {
    return this.apollo.mutate({
      mutation: ImportMembersRequest,
      variables: request
    });
  }

  getUsers(request: GetUsersResquestObject): Observable<GetUsersResponseObject> {
    return this.apollo.query({
      query: getUsers,
      variables: request,
      fetchPolicy: 'network-only'
    });
  }

  deleteUser(userId: number): Observable<GetUsersResponseObject> {
    return this.apollo.mutate({
      mutation:  deleteUser,
      variables: { id: userId}
    });
  }
  
  getUserLogs(request: UserLogsReqObject): Observable<GetUsersResponseObject> {
    return this.apollo.query({
      query: USER_LOGS,
      variables: request,
      fetchPolicy: 'network-only'
    });
  }


}
