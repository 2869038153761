import { CovidStatusFilter } from 'app/store/people-store/people.model';

export enum VaccinationStatusEnum {
    VACCINATED = 'vaccinated',
    WEEKLY = 'weeklyTest',
    NONE = 'neither'
}

export enum TestingStatusEnum {
    RESULTS = 'results',
    NO_RESULTS = 'noresults',
    VACCINATED = 'vaccinated',
    NO_PLAN = 'noplan'
}

export enum ReturnWorkStatusEnum {
    NEGATIVE_TEST = 'negative',
    CDC_ISOLATION = 'isolation',
    PROVIDER = 'provider'
}

export enum LoginDevice {
    WEB = 'WEB',
    IPHONE = 'IPHONE',
    ANDROID = 'ANDROID'
}

export enum FileEntity {
    IMPORT_FILE = 'IMPORT_FILE',
    VACCINATION_CARD = 'VACCINATION_CARD',
    TEST_RESULT = 'TEST_RESULT',
    OTHER = 'OTHER',
    LOGO = 'LOGO'
}

export enum VaccineManufacturer {
    PFIZER = 'PFIZER',
    BIONTECH = 'BIONTECH',
    JOHNSON_AND_JOHNSON = 'JOHNSON_AND_JOHNSON',
    MODERNA = 'MODERNA'
}

export enum Vaccine {
    FIRST_DOSE_COVID_19 = 'FIRST_DOSE_COVID_19',
    SECOND_DOSE_COVID_19 = 'SECOND_DOSE_COVID_19',
    FIRST_BOOSTER_SHOT = 'FIRST_BOOSTER_SHOT',
}

export enum ActivityName {
    VACCINATION_1 = 'VACCINATION_1',
    VACCINATION_2 = 'VACCINATION_2',
    VACCINATION_BOOSTER_1 = 'VACCINATION_BOOSTER_1',
    SCREENING = 'SCREENING',
    TEST_REPORT = 'TEST_REPORT',
    CONTACT_TRACING = 'CONTACT_TRACING',
    TEST_REPORT_FOLLOW_UP = 'TEST_REPORT_FOLLOW_UP',
    GET_COVID_TEST_EVERY_X_DAYS = 'GET_COVID_TEST_EVERY_X_DAYS',
    PLAN_TO_DO_NEITHER = 'PLAN_TO_DO_NEITHER',
    DO_NOT_HAVE_TEST_RESULTS = 'DO_NOT_HAVE_TEST_RESULTS',
    DO_NOT_PLAN_TO_GET_RESULT = 'DO_NOT_PLAN_TO_GET_RESULT'
}

export enum WhichTestDidYouReceive {
    PCR_OR_ANTIGEN_TEST = 'PCR_OR_ANTIGEN_TEST'
}

export enum DocumentType {
    CDC_VACCINATION_RECORD = 'CDC_VACCINATION_RECORD',
    WHO_CERTIFICATION_OF_VACCINATION = 'WHO_CERTIFICATION_OF_VACCINATION',
    NHS_VACCINATION_RECORD = 'NHS_VACCINATION_RECORD',
    EU_DIGITAL_COVID_CERTIFICATE = 'EU_DIGITAL_COVID_CERTIFICATE',
    HEALTH_CARE_PROVIDER_VACCINATION_RECORD = 'HEALTH_CARE_PROVIDER_VACCINATION_RECORD'
}

export interface LocalFile {
    documentType: DocumentType;
    fileName: string;
    path: string;
    data: string;
    uri: string;
    fileSizeBytes: string;
    filePath: string;
    fileExtension: string;
}

export interface Vaccination {
    vaccineManufacturer: VaccineManufacturer;
    vaccine: Vaccine;
    vaccineLotNumber: string;
    dateObj: DateObj;
    vaccinationDate: string;
    healthCareProviderOfficeOrSite: string;
}

export interface DateObj {
    day: string;
    month: string;
    year: string;
}

export interface Activity {
    heading: string;
    note: string;
    result: string;
    date: string;
    status: string;
}

export interface Pagination {
    page: number;
    items: number;
}

export interface PaginationResponse {
    totalRecords?: number;
    totalPages?: number;
    page?: number;
}

export enum Diagnosis {
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
    PENDING = 'PENDING'
}

export interface VaccinationCards {
    documentType?: DocumentType | FileEntity;
    fileName: string;
    path?: string;
    imgBlob?: Blob | File;
    uri?: string;
    fileSizeBytes: string;
    filePath: string;
    fileExtension: string;
    medicalActivityId?: number;
}

export interface VaccinationCardAddInput {
    documentType: DocumentType;
    filePath: string;
    fileName: string;
    fileSizeBytes: string;
    fileExtension: string;
}

export interface TestResultFile {
    medicalActivityId?: number;
    filePath: string;
    fileName: string;
    fileSizeBytes: string;
    fileExtension: string;
}

export interface TestResultFileAddInput {
    filePath: string;
    fileName: string;
    fileSizeBytes: string;
    fileExtension: string;
}

export enum Roles {
    SUPER_ADMIN = 'SUPER_ADMIN',
    JUNIOR_MANAGER = 'JUNIOR_MANAGER',
    SENIOR_MANAGER = 'SENIOR_MANAGER',
    ORG_ADMIN = 'ORG_ADMIN',
    MEMBER = 'MEMBER'
}

export enum RolesId {
    SUPER_ADMIN = 1,
    JUNIOR_MANAGER = 2,
    SENIOR_MANAGER = 3,
    ORG_ADMIN = 5,
    MEMBER = 4
}


export enum VaccinationStatus {
    VACCINATION_1 = 'VACCINATION_1',
    VACCINATION_2 = 'VACCINATION_2',
    VACCINATION_BOOSTER_1 = 'VACCINATION_BOOSTER_1',
    FULLY_VACCINATED = 'FULLY_VACCINATED'
}

export enum VaccinationStatusFilter {
    NOT_VACCINATED = 'NOT_VACCINATED',
    PARTIALLY_VACCINATED = 'PARTIALLY_VACCINATED',
    FULLY_VACCINATED = 'FULLY_VACCINATED',
    FULLY_VACCINATED_WITH_BOOSTER = 'FULLY_VACCINATED_WITH_BOOSTER'
}

export enum ScreeningStatusFilter {
    SYMPTOMS = 'SYMPTOMS',
    NO_SYMPTOMS = 'NO_SYMPTOMS',
}

export enum EngagementStatusFilter {
    RESPONDED = 'RESPONDED',
    NOT_RESPONDED = 'NOT_RESPONDED',
}

export enum ComplianceStatus {
    VACCINATED = 'VACCINATED',
    TEST_RESULTS = 'TEST_RESULTS',
    IN_QUARANTINE = 'IN_QUARANTINE',
    TEST_SENT = 'TEST_SENT',
    TEST_TAKERS = 'TEST_TAKERS',
    NO_RESPONSE = 'NO_RESPONSE'
}

export interface UserRefinements {
    covidStatus?: CovidStatusFilter;
    dateFrom?: string;
    dateTo?: string;
    roles?: any[];
    vaccinationStatus?: VaccinationStatusFilter;
    screeningStatus?: ScreeningStatusFilter;
    engagementStatus?: EngagementStatusFilter;
    complianceStatus?: ComplianceStatus;
    organizationId?: number;
    allFilter?: UserListAllFilter;
    userId?: number;
    listId?: number;
}
enum UserListAllFilter {
    ORGANIZATION = 'ORGANIZATION',
    COMPLIANCE = 'COMPLIANCE'
}


export enum SortOrder {
    ASC = 'ASC',
    DESC = 'DESC'
}

export interface Sequence {
    id: number;
    name: string;
    activities?: Object;
    sentEveryDays?: number;
    startingDateTime?: string;
}

export interface UserRight {
    rightName: string;
    rightGroup: string;
    administrator: boolean;
    juniorManager: boolean;
    seniorManager: boolean;
}

export enum InvitationStatus {
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED',
    PENDING = 'PENDING'
}
export interface DashboardRefinments {
    covidStatus?: CovidStatusFilter2;
    dateForm?: string;
    dateTo?: string;
    listId?: number;
}
export interface CovidStatusFilter2 {
    value?: string;
    operator?: Operator;
}
export enum Operator {
    IS = 'IS',
    IS_NOT = 'IS_NOT',
    IS_BLANK = 'IS_BLANK',
    CONTAINS = 'CONTAINS',
}

export enum ModelsValues {
    USER = 'USER',
    MEDICAL_ACTIVITY = 'MEDICAL_ACTIVITY',
    ROLE = 'ROLE',
    ORGANIZATION = 'ORGANIZATION',
    EMPLOYEE_IMPORT_FILE = 'EMPLOYEE_IMPORT_FILE'
}

export interface TimeZones {
    id: number;
    name?: string;
    code?: string;
    offset?: string;
}
export interface VaccinationRecords {
    vaccineManufacturer: VaccineManufacturer;
    vaccine: Vaccine;
    vaccineLotNumber: string;
    dateObj: DateObj;
    vaccinationDate: string;
    healthCareProviderOfficeOrSite: string;
    medicalActivityId?: number;
}
export interface SingleImage {
    fileName: string;
    fileSize: number;
    fileType: string;
    fileEntity: FileEntity;
    file: Blob | File;
}
export interface ActivityLog {
    activityName?: string;
    createdAt?: string;
    diagnosis?: string;
}
