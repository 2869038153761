import { NgModule, ErrorHandler } from '@angular/core';
import Appsignal from '@appsignal/javascript';
import { createErrorHandlerFactory } from '@appsignal/angular';

import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment} from '../environments/environment';

import { RelativeTimePipe } from './shared/pipes/relative-time.pipe';

import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { Interceptor } from './shared/interceptors/token.interceptor';
import { HttpErrorInterceptor } from './shared/interceptors/error.interceptor';

// stores services
import { UserService } from './store/users-store/user.service';
import { UserState } from './store/users-store/user.state';
import { PeopleState } from './store/people-store/people.state';
import { PeopleService } from './store/people-store/people.service';
import { TaskWorkflowState } from './store/task-workflow-store/task-workflow.state';
import { TaskWorkflowService } from './store/task-workflow-store/task-workflow.service';
import { PersonActivityState } from './store/person-activity-store/person-activity.state';
import { PersonActivityService } from './store/person-activity-store/person-activity.service';
import { DashboardStatsState } from './store/dashboard-stats-store/dashboard-stats.state';
import { DashboardStatService } from './store/dashboard-stats-store/dashboard-stats.service';
import { OrganizationService } from './store/OrganizationStore/organization.service';
import { OrganizationState } from './store/OrganizationStore/organization.state';
import { MediaState } from './store/mediaStore/media.state';
import { MediaService } from './store/mediaStore/media.service';
import { ListState } from './store/list-store/list.state';
import { ListService } from './store/list-store/list.service';
import { GeneralState } from './store/GeneralStore/general-store.state';
import { GeneralService } from './store/GeneralStore/general-store.service';
import { TypePolicyOptions } from './store/store-helpers';
import { OTPGuard } from './shared/guards/otp.guard';
// import { PortalModule } from '@angular/cdk/portal';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export const appsignal = new Appsignal({
  key: environment.appSignalKey,
});


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,
    NgxsModule.forRoot([UserState , PeopleState , TaskWorkflowState,
      PersonActivityState, DashboardStatsState, OrganizationState, MediaState, ListState , GeneralState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxsStoragePluginModule.forRoot({
      key: GeneralState,
      storage: StorageOption.SessionStorage
    }),
    RouterModule.forRoot(rootRouterConfig, { useHash: false, relativeLinkResolution: 'legacy' }),
  ],
  declarations: [AppComponent],
  providers: [
    RelativeTimePipe,
    UserService,
    PeopleService,
    TaskWorkflowService,
    PersonActivityService,
    DashboardStatService,
    OrganizationService,
    MediaService,
    ListService,
    GeneralService,
    OTPGuard,
    { provide: ErrorHandler, useClass: ErrorHandlerService , useFactory: createErrorHandlerFactory(appsignal) },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    // gql
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache({
            addTypename: false,
            typePolicies: TypePolicyOptions

          }),
          link: httpLink.create({
            uri: environment.apiURL,
          }),
        };
      },
      deps: [HttpLink],
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
