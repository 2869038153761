import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'filter-chip',
  templateUrl: './filter-chip.component.html',
  styleUrls: ['./filter-chip.component.scss']
})
export class FilterChipComponent {
  @Input() public tagName: string
  @Output() onClear: EventEmitter<any> = new EventEmitter();

  clear(): void {
    this.onClear.emit();
  }

}
