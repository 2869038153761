import jwt_decode from 'jwt-decode';
import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {BehaviorSubject, fromEvent, merge, Observable, of, Subject} from 'rxjs';
import { UserService } from 'app/store/users-store/user.service';
import {filter, mapTo, switchMap, take} from 'rxjs/operators';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import {LocalStoreService} from '../services/local-store.service';
import { RolesId } from '../util/types';
import {UtilService} from '../services/utils.service';
@Injectable()
export class Interceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
  online$ : Observable<any>
  constructor(private injector: Injector, private utils: UtilService,  private authService: JwtAuthService , private userService: UserService, private ls: LocalStoreService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.online$ = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.online$.subscribe(res => {
      if(!res){
        this.utils.showSnackBar('You\'re Offline, Please Check your internet Connectivity')
      }
    })
    if (request?.body?.operationName == 'getNewAccessToken') {
      return next.handle(request);
    }
    const authToken = this.authService.getJwtToken();
    if (authToken) {
      const decodedAuth: any = jwt_decode(authToken);

      if (Math.floor(Date.now() / 1000) > decodedAuth.exp) {
        if (!this.refreshTokenInProgress) {
          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);
          if (this.authService.getRefToken()) {

            return this.userService.refreshToken(this.authService.getRefToken(), authToken).pipe(
              switchMap((authResponse) => {
                this.authService.setUserToken(authResponse.data.getNewAccessToken.token);
                this.authService.setUserRefreshToken(authResponse.data.getNewAccessToken.refreshToken);
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(authResponse.data.getNewAccessToken.token);
                return next.handle(this.injectToken(request));
              })
              );
          } else {
            this.authService.signout();
            return;
          }
        } else {
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap((res) => {
                return next.handle(this.injectToken(request));
            })
        );
        }
      } else {
        return next.handle(this.injectToken(request));
      }
    } else {
      return next.handle(request);
    }
  }

  injectToken(request: HttpRequest<any>) {
    const prole = this.ls.getItem('PRIOTY_ROLE');
    let _prole = 'false';
    if (prole) {
      if (parseInt(prole) == RolesId.SUPER_ADMIN) {
        _prole = 'true';
      }
    }
    const authToken = this.authService.getJwtToken();
    return request.clone({
        setHeaders: {
            Authorization: `Bearer ${authToken}`,
            HasSuperRole: _prole
        }
    });
  }
}
