export const TypePolicyOptions = {
    Query: { 
      fields:{
        getContentData : { 
          merge(existing,incoming){
            return incoming
          }
        },
        getUserVaccinationCards  : { 
          merge(existing,incoming){
            return incoming
          }
        },
        getMedicalActivities   : { 
          merge(existing,incoming){
            return incoming
          }
        },
      }
    }
  }