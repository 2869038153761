import { gql } from "apollo-angular";

export const PresignedUrlRequest = gql`
query getPreSignedUrl($fileName:String!,$fileEntity:FileEntity!) {
  getPreSignedUrl(fileName:$fileName,fileEntity:$fileEntity){
    url
    filePath
  }
}`;

export const GetVaccinationCards = gql`
query getUserVaccinationCards($fileEntities: [FileEntity]){
  getUserVaccinationCards(fileEntities: $fileEntities){
    medicalActivityId
    documentType
    filePath
    fileName
    fileSizeBytes
    fileExtension
  }
}`;

export const exportCsv = gql`
  mutation exportCsv(
      $model: ModelsValues!
      $filters: JSONObject
    ) {
      exportCsv(
        model: $model
        filters: $filters
      )
      {
        message
      }
    }
`