import {State, Action, StateContext} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { GeneralService } from './general-store.service';
import * as actions from './general-store.actions'
import * as model from './general-store.model'
import { UtilService } from 'app/shared/services/utils.service';
import {UserService} from '../users-store/user.service';
import {GeneralStateModel} from './general-store.model';
@State<model.GeneralStateModel>({
    name: 'General',
    defaults: {
       loading: null,
       error: null,
       sequences: [],
       sessionOTPVerified: false,
        ContentData: []
    }
})

@Injectable()
export class GeneralState {
    constructor(
        private apiService: GeneralService,
        private userService: UserService,
        private utils: UtilService ,
    ) {}

    @Action(actions.GetSequencesCriteria)
    getSequenceCriteria({patchState}, {userId}){
        patchState({ loading: true})
        return this.apiService.getSequenceCriteria(userId)
        .pipe(catchError(error => {
            this.utils.showSnackBar(error)
             patchState({error: true})
            return [];
        }))
        .pipe(
            tap((res: model.GeneralStoreResponse) => {
                patchState({sequences : res.data.getContentData.sequences , loading: false})
            })
        );
    }
    @Action(actions.ContentData)
    contentData({ patchState}: StateContext<GeneralStateModel>) {
        return this.userService.getContextData()
            .pipe(
                tap((res) => {
                    patchState({ContentData: res.data.getContentData })
                })
            ).pipe(catchError(error => {
                return [];
            }));
    }

    @Action(actions.SessionVerifiedByOTP)
    markSessionVerified({ patchState }: StateContext<GeneralStateModel>){
        patchState({sessionOTPVerified: true})
    }
}
