import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { catchError, tap } from 'rxjs/operators';
import { DashboardStatsStateModel } from './dashboard-stats.model';
import { DashboardChartAction, DashboardStatsAction, SetChartExtraAction } from './dashboard-stats.action';
import { DashboardStatService } from './dashboard-stats.service';
import { appsignal } from 'app/app.module';
import { UtilService } from 'app/shared/services/utils.service';

@State<DashboardStatsStateModel>({
    name: 'DashboardStats',
    defaults: {
        loading: null,
        stats: null,
        covidPositiveData: [],
        newCasesData: [],
        chartLoading: null,
        extra : [
            {name: 'Screenings sent', weight: 0},
            {name: 'No response', weight: 0},
            {name: 'COVID Positive', weight: 0}
        ]
    }
})

@Injectable()
export class DashboardStatsState {

    constructor(private apollo: Apollo, public utils: UtilService  , private apiService: DashboardStatService) {}

    @Action(DashboardStatsAction)
    getDashboardStats({patchState}: StateContext<DashboardStatsStateModel>, {reqObj}) {
        patchState({
            loading: true
        });

        return this.apiService.getDashboardStats(reqObj)
            .pipe(
                tap((res) => {
                    patchState({ loading: null, stats: res.data.dashboardStats});
                })
            ).pipe(catchError(error => {
                this.utils.showSnackBar(error);
                patchState({loading: false});
                appsignal.sendError(error , (span) => {
                    span.setAction('Dashboard Stat Error ');
                    span.setNamespace('dashboard');
                    span.setTags({ tag1: 'dashboard' });
                });
                return [];
            }));
    }

    @Action(DashboardChartAction)
    getDashboardChartData({patchState}: StateContext<DashboardStatsStateModel>, {reqObj}) {
        patchState({
            chartLoading: true
        });
        return this.apiService.getDashboardChartData(reqObj)
            .pipe(
                tap((res) => {
                    patchState({
                        chartLoading: null,
                        covidPositiveData: res.data.getDashboardChartData.seriesOne,
                        newCasesData: res.data.getDashboardChartData.seriesTwo
                    });
                })
            ).pipe(catchError(error => {
                patchState({chartLoading: null});
                appsignal.sendError(error , (span) => {
                    span.setAction('Dashboard Chart Error ');
                    span.setNamespace('dashboard');
                    span.setTags({ tag1: 'dashboard chart' });
                });
                return [];
            }));
    }

    @Action(SetChartExtraAction)
    setExtraData({patchState, getState}: StateContext<DashboardStatsStateModel>, {index, series}) {
        if ( index === -1 ) { patchState({
            extra:  [
                {name: 'Screenings sent', weight: 0},
                {name: 'No response', weight: 0},
                {name: 'COVID Positive', weight: 0}
            ]
        });
        } else {
            const state = getState();

            const extraData =
                series === 'COVID Postive' ? state.covidPositiveData.filter((item, i) => (index === i)) :
                state.newCasesData.filter((item, i) => (index === i));

            const extra = [
                    {name: 'Screenings sent', weight: extraData[0].extra.screening},
                    {name: 'No response', weight: extraData[0].extra.noResponse},
                    {name: 'COVID Positive', weight: extraData[0].extra.covidPositive}
            ];
            patchState({
                extra
            });
        }
    }
}
