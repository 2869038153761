import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { JwtAuthService } from "../services/auth/jwt-auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";


@Injectable()
export class UserRoleGuard implements CanActivate {
  constructor(private router: Router, private jwtAuth: JwtAuthService, private snack: MatSnackBar) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
    var token = this.jwtAuth.getJwtToken();
        
    if ( token ) {
      var userRole = await this.jwtAuth.getRole();
      if ( route.data.RolesId && route.data.RolesId.includes(userRole)) {
        return true
      }
      var permissions = await this.jwtAuth.getPermissions();
      if ( permissions.length > -1) {
        return true;
      }
      return this.redirect();
    } 
    return this.redirect();
    
  }

  redirect() {
    this.jwtAuth.signout();
    this.snack.open('You do not have access to this page!', 'OK', {duration: 5000});
    this.router.navigateByUrl('/sessions/error');
    return false;
  }
}
