import { gql } from "apollo-angular";

export const EditListRequest = gql`
  mutation updateList(
      $id: Int!
      $listName: String!
      $description: String!
      $department: String
      $sequences: [Int]!
    ) {
      updateList(
        id: $id
        listName: $listName
        description: $description
        department: $department
        sequences: $sequences
      )
      {
        message
      }
    }
`;


export const GetLists = gql`
query getImportList(
  $pagination: Pagination
  $refinements: ListRefinements
  $search: String
  $sort: String
  $sortOrder: SortOrder
  ) {
    getImportList(
      pagination: $pagination
      search: $search
      sort: $sort
      refinements: $refinements
      sortOrder: $sortOrder
    )
    {
      lists {
        id,
        listName,
        description,
        department,
        sequenceNames,
        sequenceNamesArray,
        sequences {
          id,
          name
        }
      },
     
      pagination {
        totalRecords,
        totalPages,
        page
      }
    }
  }
`;

export const BulkSequenceUpdate = gql`
  mutation bulkSequenceUpdate(
    $listIds: [Int]!
    $sequences: [Int]!
    )
    {
      bulkSequenceUpdate(
        listIds: $listIds
        sequences: $sequences
      )
      {
        message
      }
    }
`
export const ADD_USER_TOLIST = gql`
mutation addUserToList(
  $listId: Int!
  $firstName: String!
  $lastName: String!
  $email: String!
  $phone: String!
  $areaCode: String
  ){
    addUserToList(listId: $listId,firstName:$firstName, lastName: $lastName, email:$email, phone: $phone,areaCode:$areaCode ){
      message
    }
  }
`
export const DELETE_LIST = gql`
mutation deleteList($id: Int!){
  deleteList(id: $id){
    message
  }
  
}
`