export enum Permissions {
    upload_new_lists = 'upload_new_lists',
    edit_list = 'edit_list',
    delete_list = 'delete_list',
    create_activities = 'create_activities',
    edit_activities = 'edit_activities',
    edit_profile_data = 'edit_profile_data',
    access_log_files = 'access_log_files',
    workflow_creation = 'workflow_creation',
    workflow_edit = 'workflow_edit',
    user_creation = 'user_creation',
    editing = 'editing',
    deleting = 'deleting',
}