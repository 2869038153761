import { gql } from "apollo-angular";

export const AddOrganizationRequest = gql`
mutation addOrganization($name:String!,$logo: String, $emailAddress: String!,$street: String!,$address: String,
  $city: String!,$state: String!,$zipCode: String!,$domain: String!, $phone: String!,$timezoneId:Int,$policyUrl: String,$sequences: [SequenceInput]!) {
  addOrganization(name:$name,logo: $logo,state: $state,zipCode: $zipCode,emailAddress:$emailAddress, address: $address
    city:$city, domain: $domain, phone:$phone,street: $street, timezoneId:$timezoneId,policyUrl:$policyUrl,sequences: $sequences){
    message
  }
}`;

export const SequenceContent = gql`
query GetContentData($userId: Int) {
  getContentData(userId: $userId) {
    sequences {
      id
      name
      route
      color
      isEligible
      message
    }
  }
}
`
