
import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { PeopleModel, PreSignedModel } from './people.model';
import { PeopleState} from './people.state';

@Injectable()
export class PeopleSelectors {
    @Selector([PeopleState])
    static preSignedUrl(state) {
        return state;
    }

    @Selector([PeopleState])
    static getPeople(state: PeopleModel) {
        return state;
    }
    @Selector([PeopleState])
    static getPeoplePagination(state: PeopleModel) {
        return state;
    }
    @Selector([PeopleState])
    static isComplete(state: PeopleModel) {
        return state.loadingPeople;
    }
    @Selector([PeopleState])
    static jManagers(state: PeopleModel) {
        return state;
    }
    @Selector([PeopleState])
    static userLogs(state: PeopleModel) {
        return {
            userLogs: state.userLogs,
            pagination: state.userLogsPagination,
            loading: state.loading
        };
    }

}
