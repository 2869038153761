import { gql } from "apollo-angular";

export const getPersonActivity = gql`
  query 
    getMedicalActivities(
        $userId: Int,
        $sort: String,
        $orderBy: SortOrder,
        $pagination: Pagination      
    )
    {
      getMedicalActivities(
        userId: $userId,
        sort: $sort,
        orderBy: $orderBy
        pagination: $pagination
      )
      {
        id,
        activityName,
        result,
        screeningTermsConditions,
        firstName,
        lastName,
        dateOfBirth,
        email,
        testEveryXDays,
        planToDoNeither,
        whichTestDidYouReceive,
        testAdministered,
        testAdministeredDate,
        diagnosis,
        dontHaveTestResult,
        vaccinated,
        activityDate,
        color,
        dontPlanToGetTested,    
        VaccinationCards{
          documentType,
          filePath,
          fileName,
          fileExtension,
          fileSizeBytes
        }
        TestResultFiles {
          filePath,
          fileName,
          fileExtension,
          fileSizeBytes
        }
        vaccineManufacturer,
        vaccine,
        vaccineLotNumber,
        vaccinationDate,
        healthCareProviderOfficeOrSite,
        createdAt,
        ContactTracings {
          
          User {
            id
            firstName
            lastName
            email
            areaCode
            phone
            mobileVerified
          }
        }
        isHidden
        displayTitle
        subTitle
      }
    }
`;

export const addPersonActivity = gql`
 mutation 
  addMedicalActivity(
      $userId: Int
      $activityName: ActivityName!
      $screeningTermsConditions: Boolean
      $firstName: String
      $lastName: String
      $dateOfBirth: String
      $email: String
      $testEveryXDays: Boolean
      $planToDoNeither: Boolean
      $whichTestDidYouReceive: WhichTestDidYouReceive
      $testAdministered: String
      $testAdministeredDate: String
      $diagnosis: Diagnosis
      $dontHaveTestResult: Boolean
      $vaccinated: Boolean
      $dontPlanToGetTested: Boolean
      $TestResultFiles: [TestResultFileAddInput]
      $VaccinationCards: [VaccinationCardAddInput]
      $personalCode: String
      $vaccineManufacturer: VaccineManufacturer
      $vaccine: Vaccine
      $vaccineLotNumber: String
      $vaccinationDate: String
      $healthCareProviderOfficeOrSite: String
      $receivedProvider: Boolean, 
      $metCdcIsolation: Boolean, 
      $receivedNegativeTest: Boolean, 
      $lastThreeDaysSymptoms: Boolean, 
      $confirmedOrProbableCovidCase: Boolean, 
      $lastFourteenDaysDiagnosis: Boolean
    )
    {
      addMedicalActivity(
        userId: $userId
        activityName:$activityName
        screeningTermsConditions: $screeningTermsConditions  
        firstName: $firstName
        lastName: $lastName
        dateOfBirth: $dateOfBirth
        email: $email
        testEveryXDays: $testEveryXDays
        planToDoNeither: $planToDoNeither
        whichTestDidYouReceive: $whichTestDidYouReceive
        testAdministered: $testAdministered
        testAdministeredDate: $testAdministeredDate
        diagnosis: $diagnosis
        dontHaveTestResult: $dontHaveTestResult
        vaccinated: $vaccinated
        dontPlanToGetTested: $dontPlanToGetTested
        TestResultFiles: $TestResultFiles
        VaccinationCards: $VaccinationCards
        personalCode: $personalCode
        vaccineManufacturer: $vaccineManufacturer
        vaccine: $vaccine
        vaccineLotNumber: $vaccineLotNumber
        vaccinationDate: $vaccinationDate
        healthCareProviderOfficeOrSite: $healthCareProviderOfficeOrSite
        receivedProvider: $receivedProvider, 
        metCDCIsolation: $metCdcIsolation, 
        receivedNegativeTest: $receivedNegativeTest, 
        lastThreeDaysSymptoms: $lastThreeDaysSymptoms, 
        confirmedOrProbableCovidCase: $confirmedOrProbableCovidCase, 
        lastFourteenDaysDiagnosis: $lastFourteenDaysDiagnosis
      )
      {
        message
      }
    }
`

export const GETUVMD = gql`
query 
  getUserVaccinationMenuDetails ( $userId: Int){
    getUserVaccinationMenuDetails (userId: $userId) {
      vaccines
    currentActivity
    getCovidTestEveryXDays
    planTodoNeither
    notification {
      message
      date
    }
    vaccinationStatus
    currentStatus
    }
  }`
export const updatePersonActivity = gql`
 mutation 
  updateMedicalActivity(
      $userId: Int
      $id: Int!
      $activityName: ActivityName!
      $screeningTermsConditions: Boolean
      $firstName: String
      $lastName: String
      $dateOfBirth: String
      $email: String
      $testEveryXDays: Boolean
      $planToDoNeither: Boolean
      $whichTestDidYouReceive: WhichTestDidYouReceive
      $testAdministered: String
      $testAdministeredDate: String
      $diagnosis: Diagnosis
      $dontHaveTestResult: Boolean
      $vaccinated: Boolean
      $dontPlanToGetTested: Boolean
      $TestResultFiles: [TestResultFileUpdateInput]
      $VaccinationCards: [VaccinationCardUpdateInput]
      $vaccineManufacturer: VaccineManufacturer
      $vaccine: Vaccine
      $vaccineLotNumber: String
      $vaccinationDate: String
      $healthCareProviderOfficeOrSite: String
    )
    {
      updateMedicalActivity(
        userId: $userId
        id: $id
        activityName:$activityName
        screeningTermsConditions: $screeningTermsConditions  
        firstName: $firstName
        lastName: $lastName
        dateOfBirth: $dateOfBirth
        email: $email
        testEveryXDays: $testEveryXDays
        planToDoNeither: $planToDoNeither
        whichTestDidYouReceive: $whichTestDidYouReceive
        testAdministered: $testAdministered
        testAdministeredDate: $testAdministeredDate
        diagnosis: $diagnosis
        dontHaveTestResult: $dontHaveTestResult
        vaccinated: $vaccinated
        dontPlanToGetTested: $dontPlanToGetTested
        TestResultFiles: $TestResultFiles
        VaccinationCards: $VaccinationCards
        vaccineManufacturer: $vaccineManufacturer
        vaccine: $vaccine
        vaccineLotNumber: $vaccineLotNumber
        vaccinationDate: $vaccinationDate
        healthCareProviderOfficeOrSite: $healthCareProviderOfficeOrSite
      )
      {
        message
      }
    }
`
export const GETUVC = gql`
 query 
  getUserVaccinationCards($userId: Int,$fileEntities:[FileEntity]){
    getUserVaccinationCards(userId: $userId, fileEntities:$fileEntities) {
     type
     medicalActivityId
     documentType
     filePath
     fileName
     fileSizeBytes
     fileExtension
    }
  }
`
export const AddMultipleMedicalActivitiesRequest = gql`
mutation addMultipleMedicalActivities(
  $activities: [BulkMedicalActivityInput],
  $userId: Int
){
  addMultipleMedicalActivities(
    activities: $activities,
    userId: $userId
  ){
    message
  }
}`;

export const DeletePersonActivity = gql`
  mutation deleteMedicalActivity(
      $id: Int!
    )
    {
      deleteMedicalActivity(
        id: $id
      )
      {
        message
      }
    }
`;

export const GET_PERSON_ACTIVITY = gql`
  query GetUserActivityLogs($userId: Int $pagination: Pagination, $sort: String, $orderBy: SortOrder, $search: SearchUserActivity) {
    getUserActivityLogs(userId: $userId, pagination: $pagination, sort: $sort, orderBy: $orderBy, search: $search) {
      activities {
        activityName
        createdAt
        result
        activityDate
      }
      pagination {
        totalRecords
        totalPages
        page
      }
    }
  }
`
export const AddBulkContactTracing = gql`
mutation($contacts: [bulkContactTracing]!, $userId: Int){
  addContactTracings(contacts: $contacts, userId: $userId) {
    message
  }
}`;
export const UpdateContactTracing = gql`
mutation UpdateContactTracings($contacts: [bulkContactTracing]!, $medicalActivityId: Int!) {
  updateContactTracings(contacts: $contacts, medicalActivityId: $medicalActivityId) {
    message
  }
}`;
export const GetUserContacts = gql`
query GetRecentContactTracing($userId: Int) {
  getRecentContactTracing(userId: $userId) {
    id
    activityName
    ContactTracings {
      User {
        firstName
        lastName
        email
        phone
        areaCode
        formattedPhoneNumber
      }
    }
  }
}
`

export const  addScreening = gql`
mutation AddMedicalActivity($userId: Int,$activityName: ActivityName!, $lastFourteenDaysDiagnosis: Boolean,$confirmedOrProbableCovidCase: Boolean,$lastThreeDaysSymptoms: Boolean
  ) {
  addMedicalActivity(userId:$userId, activityName: $activityName, lastFourteenDaysDiagnosis: $lastFourteenDaysDiagnosis , confirmedOrProbableCovidCase: $confirmedOrProbableCovidCase , lastThreeDaysSymptoms: $lastThreeDaysSymptoms  ) {
  message  
  }
}
`