<mat-toolbar class="topbar">
  <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
    fxHide.md=""
    fxHide.lg=""
  >
    <mat-icon>menu</mat-icon>
  </button>
  <app-breadcrumb></app-breadcrumb>

  <!-- <app-toast></app-toast> -->
  
  <span fxFlex></span>
  <button
    mat-icon-button
    matTooltip="Notifications"
    (click)="toggleNotific()"
    [style.overflow]="'visible'"
    class="topbar-button-right notification-icon"
  >
    <mat-icon>notifications</mat-icon>
  </button>
  <span class="separator-bar"></span>
  <button *ngIf="(currUser$ | async) as user"
    mat-button
    [matMenuTriggerFor]="accountMenu"
    class="topbar-button-right img-button top-bar-avatar"
  >
    {{user.firstName}} {{user.lastName}} 
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>

  <mat-menu #accountMenu="matMenu" class="avatar-menu">
    <button mat-menu-item [routerLink]="['/profile']">
      <span>Profile</span>
    </button>
    <button mat-menu-item (click)="jwtAuth.signout()" class="signout-button">
      <span>{{ "Logout" | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
