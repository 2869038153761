import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { OTPGuard } from './shared/guards/otp.guard';
import { Permissions } from './shared/util/permissions';
import { RolesId } from './shared/util/types';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/overview',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session'}
      },
      {
        path: 'invitation',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Invite' }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [ AuthGuard, OTPGuard ],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'Dashboard', Permissions : Permissions.upload_new_lists}
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'Profile', breadcrumb: 'Profile', RolesId : [RolesId.SUPER_ADMIN, RolesId.ORG_ADMIN, RolesId.SENIOR_MANAGER, RolesId. JUNIOR_MANAGER]}
      },
      {
        path: 'people',
        loadChildren: () => import('./views/people/people.module').then(m => m.CrudsModule),
        data: { title: 'People', breadcrumb: 'People', RolesId : [RolesId.SENIOR_MANAGER]}
      },
      {
        path: 'list-people',
        loadChildren: () => import('./views/lists-people/list-people.module').then(m => m.ListPeopleModule),
        data: { title: 'Lists & People', breadcrumb: 'Lists & People', Permissions : Permissions.upload_new_lists}
      },
      {
        path: 'tasks',
        loadChildren: () => import('./views/tasks/task.module').then(m => m.TasksModule),
        data: { title: 'Tasks', breadcrumb: 'Tasks', Permissions : Permissions.workflow_creation}
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        data: { title: 'Users', breadcrumb: 'Users', Permissions : Permissions.user_creation}
      },
      {
        path: 'organizations',
        loadChildren: () => import('./views/organizations/organizations.module').then(m => m.OrganizationsModule),
        data: { title: 'Organizations', breadcrumb: 'Organizations', RolesId : [RolesId.SUPER_ADMIN]}
      },
      {
        path: 'organization-setting',
        loadChildren: () => import('./views/organization-setting/organization-setting.module').then(m => m.OrganizationSettingModule),
        data: { title: 'Organization Settings', breadcrumb: 'Organization Settings', RolesId : [RolesId.ORG_ADMIN]}
      },
      {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule),
        data: { title: 'Admins', breadcrumb: 'Admins', RolesId : [RolesId.SUPER_ADMIN]}
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

