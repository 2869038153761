import { InvitationStatus, Roles } from 'app/shared/util/types';
import * as UserModel from './user.model';

export class AdminLogin {
    static readonly type = '[ADMIN] Login';
    constructor(public adminObj: UserModel.AdminObj) {}
}

export class GetOrganaizationsAction {
    static readonly type = '[ADMIN] GetOrgs';
}

export class GetContextDataAction {
    static readonly type = '[ADMIN] GetContext';
    constructor(public userId?: number) {}
}
export class GetUserDataAction {
    static readonly type = '[ADMIN] GetUser';
}
export class GetNewTokens {
    static readonly type = '[TOKENS] GetNewTokens';
    constructor(public tokensObj: UserModel.NewTokenReqObj ) {}
}
export class SetCurrentUserRoles {
    static readonly type = '[ROLES] SetRoles';
}
export class ChangePassword {
    static readonly type = '[PASSWORD] Change Password';
    constructor(public obj: Object ) {}
}
export class GetBackendUsers {
    static readonly type = '[ADMIN] Get Backend Users';
    constructor(public reqObj: UserModel.BackendUsersRequest ) {}
}
export class DeleteBackendUser {
    static readonly type = '[ADMIN] Delete Backend User';
    constructor(public userId: number,
        public roles: Roles[],
        public invitationStatus?: InvitationStatus[] ) {}
}
export class DeleteBackendUserFromState {
    static readonly type = '[ADMIN] Delete Backend User From State';
    constructor(public userId: number) {}
}
export class BackendUserResetPassword {
    static readonly type = '[ADMIN] Backend User Reset Password';
    constructor(public userId: number ) {}
}
export class GetPrimaryAdmins {
    static readonly type = '[ADMIN] Get Primary Admins';
}
export class UpdatePrimaryAdmin {
    static readonly type = '[ADMIN] Update Primary Admin';
    constructor(public userId: number ) {}
}
export class UpdateAdminUser {
    static readonly type = '[ADMIN] Update Admin User';
    constructor(public reqObj: UserModel.UpdateAdminUserRequestObject ) {}
}

export class UpdateUserProfile {
    static readonly type = '[ADMIN] Update User Profile';
    constructor(public reqObj: UserModel.User ) {}
}

export class GetInvitationTokenInfo {
    static readonly type = '[USER] Token Info';
    constructor(public reqObj: UserModel.InvitationTokenReqObj ) {}
}
export class AfterLoginAction {
    static readonly type = '[USER] Set Login';
}
