
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { GeneralStoreResponse } from './general-store.model';
import * as requests from './general-store.requests';

@Injectable()
export class GeneralService {
    constructor(
      private apollo: Apollo,
      ){}

    
    getSequenceCriteria(userId?:number) : Observable<GeneralStoreResponse>{
      return this.apollo.query({
        query: requests.SequenceContent,
        variables: {
          userId
        },
        fetchPolicy: 'network-only',
      })
    }

}