<div *ngIf="toast?.visible">
    <mat-card class="toast">
        <div class="toast-body">
            <p>{{toast?.message}}</p>
            <button mat-raised-button color="primary" *ngIf="toast?.showButton">{{toast?.buttonText}}</button>
        </div>
        <div>
            <button mat-icon-button (click)="dismiss()" class="dismiss-button"><mat-icon>close</mat-icon></button>
        </div>
    </mat-card>
</div>

