import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { GetOrganization } from 'app/store/OrganizationStore/organization.action';
import { CurrentRoles } from 'app/store/users-store/user.model';
import { UserSelectors } from 'app/store/users-store/user.selectors';
import { Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { JwtAuthService } from './auth/jwt-auth.service';
import * as moment from 'moment';


@Injectable({
    providedIn: 'root',
  })
export class UtilService {
    userRoles: CurrentRoles;
    @Select(UserSelectors.getRoles) roles$: Observable<CurrentRoles>;
    constructor(
        private snackbar: MatSnackBar,
        public jwtAuth: JwtAuthService,
        private router: Router,
        private store: Store
    ) {

    }
    showSnackBar(message, duration?) {
        if (message) {
            this.snackbar.open(message, 'Close',  {  duration: duration ? duration : 3000});
        }
    }
    checkLoggedIn() {
        this.roles$.pipe(skipWhile(res => !res)).subscribe(res => {
            if (this.jwtAuth.isLoggedIn()) {
                this.showSnackBar('Logging In', 5000);
                if (res?.superAdmin) {
                    this.router.navigateByUrl('/dashboard/overview');
                    return;
                }
                if (  res?.orgAdmin) {
                    this.store.dispatch(new GetOrganization());
                    this.router.navigateByUrl('/organization-setting');
                    return;
                }
                if (  res?.seniorManager) {
                    this.router.navigateByUrl('/list-people/tabs');
                    return;
                }
                if (  res?.juniorManager) {
                    this.router.navigateByUrl('/tasks/list');
                    return;
                } else {
                    this.router.navigateByUrl('/task/list');
                    return;
                }
            }
            return false;

         });

    }

    simplifyString(param) {
        let abc: string = param?.replace(/_/g, ' ');
        abc = abc?.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
        return abc;
    }

    handlePhoneErrors(form, control) {
        if (control === 'phone' && form.hasError('pattern')) {
            return 'Number must be only digits ';
        } else if (control === 'phone' && form.hasError('minlength')) {
            return 'Number must be 10 digits';
        } else if (control === 'phone' && form.hasError('maxlength')) {
            return 'Number must not exceed 10 digits';
        }
    }

    handleConfirmPasswordErrors(form, control) {
        if (control === 'confirmPassword' && form.hasError('minlength')) {
            return 'Password Must be 8  digits';
        } else if (control === 'confirmPassword' && form.hasError('equalTo')) {
            return 'New and Confirm New password must be same ';
        }
    }

    handlePasswordErrors(form, control) {
        if (control === 'password' && form.hasError('minlength')) {
            return `Password Must be Atleast 8 Characters`;
        } else if (control === 'password' && form.touched && form.value === '') {
            return `Password is required`;
        } else if (control === 'password' && form.hasError('notEqualTo')) {
            return 'old and new password must not be same ';
        }
    }


    handleNewPasswordErrors(form, control) {
        if (form.touched && form.value === '' ) { return { error: true, message: 'Password is required'}; }
        const hasUpper = /[A-Z]/.test(form.value);
        if (!hasUpper) { return  { error: true, message: 'password must contain atleast one capital letter'}; }
        const hasNumber = /\d/.test(form.value);
        if (!hasNumber) { return { error: true, message: 'password must contain number'}; }
    }

    returnError(f, control) {
        const form = f.get(control);
        if (form.hasError('touched') && form.hasError('required')) {
            return 'You must enter a value';
        } else if (form.hasError('email')) {
            return 'Email Not Valid!';
        } else if (control === 'email' && form.hasError('pattern')) {
            return 'Invalid Email ';
        } else if (control === 'phone') {
            return this.handlePhoneErrors(form, control);
        } else if (control === 'orgUrl' && form.hasError('pattern')) {
            return 'Please Enter a Valid Url';
        } else if (control === 'password' ) {
            return this.handlePasswordErrors(form, control);
        }  else if (control === 'otp') {
            return 'Password Must be 6 digits';
        } else if (control === 'confirmPassword') {
            return this.handleConfirmPasswordErrors(form, control);
        } else if (control === 'newPassword' ) {
           return this.handleNewPasswordErrors(form, control);
        }
    }

    newPassword(f, control): FormModel {
        const form = f.get(control);
        const hasNumber = /\d/.test(form.value);
        const hasUpper = /[A-Z]/.test(form.value);
        const hasChars = /[@$!%*?&]/.test(form.value);
        if (form.hasError('touched') && form.hasError('required')) {
            return { error: true, message: 'Password is required' };
        } else if (form.hasError('minlength')) {
            return { error: true, message: 'Password Must be 8  digits'};
        } else if (!hasUpper) { return  { error: true, message: 'Password must contain at least one capital letter'}; } else if (!hasChars) { return  { error: true, message: 'Password must contain at least one special character'}; } else if (!hasNumber) {
            return { error: true, message: 'Password must contain number'};
        } else if (hasUpper && hasNumber &&  !form.hasError('touched') && !form.hasError('required') ) {
            return { error: false , message: ''};
        }

    }

    labelError(form, control) {
        if (form.get(control).touched && form.get(control).errors) { return true; }
        return false;
    }

    hidePhoneNumber(phone: string) {
        if (!phone) { return ''; }
        return '******' + phone.substring(6, 10);
    }
    convertDate(date) {
        date = moment(date);
        date.set({
            hour: parseInt(moment().format('HH')),
            minute: parseInt(moment().format('mm')),
            second: parseInt( moment().format('ss'))
        });
        return  moment.utc(date).format();
    }
    toLocale(date) {
        return moment(date).local().format('YYYY-MM-DD');
    }
    fromUnix(unixDate) {
        return moment(moment.unix(unixDate / 1000).format('YYYY-MM-DD HH:MM:00') + '+00:00').local().format('YYYY-MM-DD');
    }
    scrollToTop() {
        if(document) {
            setTimeout(() => {
                let element;
                // if(this.layoutConf.topbarFixed) {
                element = <HTMLElement>document.querySelector('#rightside-content-hold');
                // } else {
                element = <HTMLElement>document.querySelector('#main-content-wrap');
                // }
                element.scrollTop = 0;
            })
        }
    }


}

interface FormModel {
    error?: boolean;
    message?: string;
}
