import { Pagination } from 'app/shared/util/types';
import {PreSignedObj, MembersObj, UserLogsReqObject, GetUsersResquestObject} from './people.model';

export class PreSignedUrl {
    static readonly type = '[PEOPLE] PreSigne'
    constructor(public preSignedObj: PreSignedObj) {}
}

export class ImportMembersAction {
    static readonly type = '[PEOPLE] ImportMembers'
    constructor(public membersObj: MembersObj) {}
}

export class GetUsersAction {
    static readonly type = '[USER] GetUsers'
    constructor(public usersRequestObject: any) {}
}

export class DeleteUserAction {
    static readonly type = '[USER] DeleteUser'
    constructor(public userId: number, public reqObj: GetUsersResquestObject) {}
}

export class GetJuniorManagers {
    static readonly type = '[USER] GetJuniorManagers'
    constructor(public usersRequestObject: any) {}
}

export class GetSingleUserAction {
    static readonly type = '[USER] GetUser'
    constructor(public usersRequestObject: any) {}
}

export class ClearUserStateAction {
    static readonly type = '[USER] ClearState'
}
export class GetUserLogs {
    static readonly type = '[USER] Get User  Logs'
    constructor(public request: UserLogsReqObject) {}
}
export class ClearUserLogs {
    static readonly type = '[USER] Clear User Logs'
}
export class SetPeoplePaginationAction {
    static readonly type = '[USER] Set People Pagination'
    constructor(public request: Pagination) {}
}

export class SetPeopleRefinementsAction {
    static readonly type = '[USER] Set People Refinements'
    constructor(public request: any) {}
}
